import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
// Styles
import { Box, Grid, styled } from "@mui/material";

// Components
import AppSidebar from "../components/AppSidebar";
import AppNavbar from "../components/AppNavbar";
import { AdminContext } from "../auth/adminContext";

const StyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
});

const OutletDashboard = () => {
  return (
    <>
      <StyledBox>
        <AppSidebar />
        <Grid sx={{ width: "100%" }}>
          <AppNavbar />
          <Outlet />
        </Grid>
      </StyledBox>
    </>
  );
};

export default OutletDashboard;

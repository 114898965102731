import React, { FunctionComponent } from "react";

// Components
import AppSubmitButton from "./AppSubmitButton";
import Loading from "../shared/Loading";
import {
  Grid,
  Modal,
  Typography,
  Box,
  styled,
  FormLabel,
  Stack,
  FormGroup,
  IconButton,
} from "@mui/material";

// Icons
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FlagIcon from "@mui/icons-material/Flag";

// Services

import { useAddUserAuthorizedData } from "../services/userAuthorize";
import { UserUnauthorizedModalProps } from "../types/users";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StyledGrid = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "20px",
  maxHeight: "83vh",
  padding: "0",
  width: "80%",
  minHeight: "30vh",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.20),0px 1px 3px 0px rgba(0,0,0,0.12);",
});

const StyledStack = styled(Stack)({
  width: "100%",
});

const AppModalUnauthorized: FunctionComponent<UserUnauthorizedModalProps> = ({
  show,
  handleClose,
  dataUser,
}) => {
  const { mutate: addUserAuthorized, isLoading: isAddingUser } =
    useAddUserAuthorizedData();
  const date = dataUser.requestDate.split("T")[0];

  const handleCloseModal = () => {
    handleClose();
  };

  const handleAuthorized = () => {
    addUserAuthorized({ email: dataUser?.email });
    handleClose();
  };

  if (isAddingUser) {
    return <Loading showBack={true} />;
  }

  return (
    <StyledModal open={show} onClose={handleCloseModal}>
      <StyledGrid container alignContent="flex-start" sx={{ padding: "2rem" }}>
        <StyledStack direction={"row"}>
          <Typography
            textTransform="uppercase"
            flex={8}
            variant="h5"
            component="div"
          >
            {dataUser.fullName} / {date}
          </Typography>
          <IconButton sx={{ height: "30px" }} onClick={handleCloseModal}>
            <CloseRoundedIcon />
          </IconButton>
        </StyledStack>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="space-between"
          sx={{ paddingTop: "1.5rem" }}
        >
          <Grid item xs={3}>
            <Grid
              item
              xs={12}
              sx={{
                textTransform: "uppercase",
                textAlign: "start",
                color: "#3db1c8",
                fontWeight: 500,
                paddingBottom: "1rem",
              }}
            >
              Personal information
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              sx={{
                textTransform: "uppercase",
                textAlign: "start",

                fontWeight: 500,
              }}
            >
              <Typography variant="body2" gutterBottom component="div">
                {dataUser?.role ? dataUser.role : "Data not entered"}
              </Typography>
            </Grid>
            <Grid item display="flex">
              <Typography variant="body2" gutterBottom component="div">
                {dataUser?.medicalSpeciality
                  ? dataUser?.medicalSpeciality
                  : "Data not entered"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              width="100%"
              sx={{ paddingTop: "1rem" }}
            >
              <FlagIcon
                sx={{ color: "#213358", paddingRight: "10px" }}
                fontSize="small"
              />
              <Typography fontSize="0.875rem">{`Origin: ${
                dataUser?.countryOrigin
                  ? dataUser?.countryOrigin
                  : "Personal information"
              }`}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              width="100%"
              sx={{ paddingTop: "5px" }}
            >
              <HomeIcon
                sx={{ color: "#213358", paddingRight: "10px" }}
                fontSize="small"
              />
              <Typography fontSize="0.875rem">{`Residence: ${
                dataUser?.country ? dataUser?.country : "Personal information"
              }`}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <Grid
              item
              xs={12}
              sx={{
                textTransform: "uppercase",
                textAlign: "start",
                color: "#3db1c8",
                fontWeight: 500,
                paddingBottom: "1rem",
              }}
            >
              description
            </Grid>

            <Grid item xs={10} display="flex">
              <Typography variant="body2" gutterBottom component="div">
                {dataUser.request
                  ? dataUser.request
                  : "User did not enter description"}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Grid
              item
              xs={12}
              sx={{
                textTransform: "uppercase",
                textAlign: "start",
                color: "#3db1c8",
                fontWeight: 500,
                paddingBottom: "1rem",
              }}
            >
              Contact info
            </Grid>

            <Grid item xs={12} display="flex">
              <EmailIcon
                sx={{ color: "#213358", paddingRight: "5%" }}
                fontSize="small"
              />
              <Typography variant="body2" gutterBottom component="div">
                {dataUser?.email ? dataUser?.email : "Data not entered"}
              </Typography>
            </Grid>

            <Grid item xs={12} display="flex">
              <PhoneIcon
                sx={{ color: "#213358", paddingRight: "5%" }}
                fontSize="small"
              />
              <Typography variant="body2" gutterBottom component="div">
                {dataUser?.phoneNumber
                  ? dataUser?.phoneNumber
                  : "Data not entered"}
              </Typography>
            </Grid>

            <AppSubmitButton
              submitbtnmargintop="40px"
              onClick={handleAuthorized}
              id="buttonAuthorized"
            >
              Authorize
            </AppSubmitButton>
          </Grid>
        </Grid>
      </StyledGrid>
    </StyledModal>
  );
};

export default AppModalUnauthorized;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Button,
  TableRow,
  TableCell,
  Tooltip,
  Input,
  styled,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import KeyRoundedIcon from "@mui/icons-material/KeyRounded";

// Services
import { useDeleteRole, useUpdateRoleData } from "../services/roles";

// Components
import PermissionsModal from "./PermissionsModal";
import AddEditModal from "../shared/AddEditModal";

// Types
import { RoleFormProps, RoleRowProps } from "../types/roles";
import AppSubmitButton from "./AppSubmitButton";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const RoleRow = ({ role }: RoleRowProps) => {
  const [openPermissionsModal, setOpenPermissionsModal] = useState(false);
  const handleOpenPermissionsModal = () => setOpenPermissionsModal(true);
  const handleClosePermissionsModal = () => setOpenPermissionsModal(false);
  const { mutate: deleteRole } = useDeleteRole();
  const { mutate: updateRole } = useUpdateRoleData();
  const [roleEditing, setRoleEditing] = useState(false);
  const handleCloseEditModal = () => setRoleEditing(false);

  const handleSpecialtyEdit = () => {
    setRoleEditing(true);
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<RoleFormProps>({
    defaultValues: {
      id: null,
      es: "",
      en: "",
      pt: "",
    },
  });

  const getEsLanguage = role.languajes.find(
    (language) => language.culture === "es"
  );

  const getEnLanguage = role.languajes.find(
    (language) => language.culture === "en"
  );

  const getPtLanguage = role.languajes.find(
    (language) => language.culture === "pt"
  );
  useEffect(() => {
    if (roleEditing) {
      setValue("id", role?.id);
      setValue("es", `${getEsLanguage?.description}`);
      setValue("en", `${getEnLanguage?.description}`);
      setValue("pt", `${getPtLanguage?.description}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleEditing]);

  return (
    <React.Fragment>
      <StyledRow
        key={role.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          align="left"
          scope="row"
          key={getEsLanguage?.id}
          sx={{ wordWrap: "anywhere", width: "28%" }}
        >
          {getEsLanguage?.description}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          key={getEnLanguage?.id}
          sx={{ wordWrap: "anywhere", width: "28%" }}
        >
          {getEnLanguage?.description}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          key={getPtLanguage?.id}
          sx={{ wordWrap: "anywhere", width: "28%" }}
        >
          {getPtLanguage?.description}
        </TableCell>
        <TableCell align="left" colSpan={3} sx={{ width: "16%" }}>
          <Tooltip title="Edit" placement="top" arrow>
            <Button
              onClick={handleSpecialtyEdit}
              sx={{ minWidth: "30px", p: "6px 8px 6px 0" }}
            >
              <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Remove" placement="top" arrow>
            <Button
              onClick={() => {
                deleteRole({ id: role.id });
              }}
              sx={{ minWidth: "30px" }}
            >
              <RemoveIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Handle Permissions" placement="top" arrow>
            <Button
              onClick={handleOpenPermissionsModal}
              sx={{ minWidth: "30px" }}
            >
              <KeyRoundedIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
        </TableCell>
      </StyledRow>
      <AddEditModal
        show={roleEditing}
        handleClose={handleCloseEditModal}
        modalHeader="Edit role"
        modalInputLabel="Role"
      >
        <>
          <Input
            {...register("es", {
              required: true,
            })}
          />
          {errors?.es?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            {...register("en", {
              required: true,
            })}
          />
          {errors?.en?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            {...register("pt", {
              required: true,
            })}
          />
          {errors?.pt?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <AppSubmitButton
            submitbtnwidth="100%"
            onClick={handleSubmit((data) => {
              updateRole({
                id: role.id,
                description: data.es,
                culture: "es",
              });
              updateRole({
                id: role?.id,
                description: data.en,
                culture: "en",
              });
              updateRole({
                id: role?.id,
                description: data.pt,
                culture: "pt",
              });
              reset();
              setRoleEditing(false);
            })}
          >
            Submit
          </AppSubmitButton>
        </>
      </AddEditModal>
      {openPermissionsModal && (
        <PermissionsModal
          show={openPermissionsModal}
          handleClose={handleClosePermissionsModal}
          roleInfo={role}
        />
      )}
    </React.Fragment>
  );
};
export default RoleRow;

import { useMutation, useQueryClient, useQuery } from "react-query";
import { Admin, GetAdminsResponse } from "../types/admins";
import http from "./axios";

// Get
export const useAdmins = (page: number) => {
  return useQuery<GetAdminsResponse, Error>(
    ["requestAdmins", page],
    async () => {
      const res = await http.get<"requestAdmins", { data: GetAdminsResponse }>(
        "admin-user/get-all-users",
        { params: { page: page, limit: 10 } }
      );
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

// Post
const addNewAdmin = (admin: {
  email: string;
  fullName: string;
  levelAdmin: number;
}) => {
  return http.post(`admin-user/add`, admin);
};

export const useAddNewAdminData = (page: number) => {
  const queryClient = useQueryClient();
  return useMutation(addNewAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestAdmins", page]);
    },
  });
};

const updateAdmin = (admin: Admin) => {
  return http.post(`admin-user/update`, admin);
};

export const useUpdateAdminData = (page: number) => {
  const queryClient = useQueryClient();
  return useMutation(updateAdmin, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestAdmins", page]);
    },
  });
};

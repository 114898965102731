import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Styles
import {
  Box,
  Grid,
  styled,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import FlagIcon from "@mui/icons-material/Flag";
import Swal from "sweetalert2";

// Services
import { useBlockUnblockUserData, useUserDetail } from "../services/users";

// Types
import { UserDetailTypes } from "../types/users";

// Components
import AppContainer from "../components/AppContainer";
import Loading from "../shared/Loading";
import { matchUserToRole, useRolesByCulture } from "../services/roles";
import Select, { SingleValue } from "react-select";
import { RolSelector } from "../types/roles";
import { addProfileToUser, useGetProfiles } from "../services/profiles";

const StyledGrid = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "20px",
  maxHeight: "83vh",
  padding: "0",
  width: "80%",
  minHeight: "30vh",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.20),0px 1px 3px 0px rgba(0,0,0,0.12);",
});

const UserDetail = () => {
  const params = useParams<{ userId: string }>();
  const { data: roles } = useRolesByCulture(1, 30, "en");
  const { data: profiles } = useGetProfiles(false);
  const { data: userInfo, isLoading, refetch } = useUserDetail(params.userId);
  const { mutate: handleBlockStatus } = useBlockUnblockUserData(params.userId);
  const user: UserDetailTypes | undefined = userInfo?.data;

  const [rolSelected, setRolSelected] = useState({
    description: "Select",
    id: 0,
  });

  const [profileSelected, setProfileSelected] = useState({
    description: "Select",
    id: 0,
  });

  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    Swal.fire({
      title: `Do you want to ${
        e.target.checked ? "block" : "activate"
      } this user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3db1c8",
      cancelButtonColor: "#cacaca",
      confirmButtonText: "Yes!",
      iconColor: "#762d70",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockStatus({
          userId: user?.id,
          isBlocked: !e.target.checked,
        });
      }
    });
  };

  const handleChangeRol = useCallback(
    (e: SingleValue<RolSelector>) => {
      if (params.userId && e) {
        matchUserToRole(parseInt(params.userId, 10), e.id)
          .then((res) => {
            if (res.data.data) {
              Swal.fire({
                title: "Rol updated successfully",
                icon: "success",
                iconColor: "#762d70",
              });
              refetch();
            } else {
              Swal.fire({
                title: "Rol updated successfully",
                icon: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [params.userId, refetch]
  );

  const handleChangeProfile = useCallback(
    (e: SingleValue<RolSelector>) => {
      if (params.userId) {
        let idProfile = 0;
        if (e?.id) {
          idProfile = e.id;
        }
        addProfileToUser(parseInt(params.userId, 10), idProfile)
          .then((res) => {
            console.log(res);
            Swal.fire({
              title: "Profile updated successfully",
              icon: "success",
              iconColor: "#762d70",
            });
            refetch();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    [params.userId, refetch]
  );

  useEffect(() => {
    if (roles?.data && userInfo?.data) {
      if (roles.data.length > 0 && userInfo.data.rolesId !== 0) {
        const value = roles.data.find((r) => r.id === userInfo.data.rolesId);
        if (value) {
          setRolSelected(value);
        }
      }
    }
  }, [roles, userInfo]);

  useEffect(() => {
    if (profiles?.data && userInfo?.data) {
      if (profiles.data.length > 0 && userInfo.data.rolesId !== 0) {
        const value = profiles.data.find(
          (r) => r.id === userInfo.data.profileId
        );
        if (value) {
          setProfileSelected(value);
        } else {
          setProfileSelected({
            description: "Select",
            id: 0,
          });
        }
      }
    }
  }, [profiles, userInfo]);

  if (isLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <AppContainer>
      <StyledGrid
        container
        spacing={0}
        alignContent="flex-start"
        sx={{ padding: "2rem" }}
      >
        {user?.createdDate && (
          <Grid item xs={12} display="flex">
            <Typography
              variant="body2"
              sx={{
                color: "#8c8c8c",
                paddingRight: "1%",
                fontSize: ".8rem",
              }}
            >
              Created on
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#3db1c8", fontWeight: 500, fontSize: ".8rem" }}
            >
              {user?.createdDate?.substring(0, 10) || null}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} display="flex">
          <Grid item xs={9}>
            <Box
              sx={{
                textAlign: "left",
                fontSize: "1.5rem",
                color: "#213358",
                fontWeight: "500",
              }}
            >
              {user?.fullName}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              sx={{ display: "flex", justifyContent: "flex-end" }}
              control={
                <Switch
                  size={"small"}
                  color="primary"
                  checked={user?.statusId === 3}
                  onChange={(e) => handleStatus(e)}
                />
              }
              label={`${user?.statusId === 1 ? "Active" : "Blocked"}`}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} display="flex" sx={{ paddingTop: "1.5rem" }}>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="middle"
              justifyContent={"space-between"}
              width={"50%"}
            >
              <Grid
                item
                xs={8}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box sx={{ color: "#3db1c8", fontWeight: 500 }}>
                  {user?.rol}
                </Box>
                <Box sx={{ color: "#213358", fontWeight: 500 }}>
                  {user?.workPlace}
                </Box>
                <Box
                  sx={{ color: "#8f8f8f", fontWeight: 500, fontSize: "1.2rem" }}
                >
                  {user?.medicalSpeciality}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                flexDirection={"column"}
                alignItems="flex-start"
                width={"100%"}
              >
                {roles?.data.length && (
                  <div className="container-select">
                    <label htmlFor="name">Select rol</label>
                    <Select
                      name="role"
                      isSearchable
                      options={roles.data}
                      getOptionLabel={(option) => option.description}
                      getOptionValue={(option) => option.id.toString()}
                      onChange={handleChangeRol}
                      value={rolSelected}
                    />
                  </div>
                )}

                {profiles?.data.length && (
                  <div className="container-select mt-5">
                    <label htmlFor="name">Select profile</label>
                    <Select
                      name="profile"
                      isClearable={userInfo?.data?.profileId !== null}
                      isSearchable
                      options={profiles.data}
                      getOptionLabel={(option) => option.description}
                      getOptionValue={(option) => option.id.toString()}
                      onChange={handleChangeProfile}
                      value={profileSelected}
                    />
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              width="100%"
              sx={{ paddingTop: "1rem" }}
            >
              {user?.countryOrigin && (
                <Grid item xs={6} display="flex" alignItems="center">
                  <FlagIcon
                    sx={{ color: "#213358", paddingRight: "5%" }}
                    fontSize="small"
                  />
                  <Typography>{`Origin: ${user?.countryOrigin}`}</Typography>
                </Grid>
              )}
              {user?.countryResidence && (
                <Grid item xs={6} display="flex" alignItems="center">
                  <HomeIcon
                    sx={{ color: "#213358", paddingRight: "5%" }}
                    fontSize="small"
                  />
                  <Typography>{`Residence: ${user?.countryResidence}`}</Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              item
              xs={12}
              sx={{
                textTransform: "uppercase",
                textAlign: "start",
                color: "#3db1c8",
                fontWeight: 500,
                paddingBottom: "1rem",
              }}
            >
              Contact info
            </Grid>
            {user?.email && (
              <Grid item xs={12} display="flex">
                <EmailIcon
                  sx={{ color: "#213358", paddingRight: "5%" }}
                  fontSize="small"
                />
                <Typography variant="body2" gutterBottom component="div">
                  {user?.email}
                </Typography>
              </Grid>
            )}
            {user?.phoneNumber && (
              <Grid item xs={12} display="flex">
                <PhoneIcon
                  sx={{ color: "#213358", paddingRight: "5%" }}
                  fontSize="small"
                />
                <Typography variant="body2" gutterBottom component="div">
                  {user?.phoneNumber}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </StyledGrid>
    </AppContainer>
  );
};

export default UserDetail;

import React, { FunctionComponent, useEffect, useState } from "react";

// Styles
import { Checkbox, FormControlLabel } from "@mui/material";

// Services
import {
  useAddPermissionData,
  useDeletePermissionPerRole,
} from "../services/permissions";

// Types
import { RolePermissionsRowProps } from "../types/permissions";

const RolePermissionRow: FunctionComponent<RolePermissionsRowProps> = ({
  role,
  permission,
  isChecked,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const { mutate: addPermission } = useAddPermissionData();
  const { mutate: removePermission } = useDeletePermissionPerRole();
  const handleChange = () => {
    setChecked(!isChecked);
    if (checked === false) {
      addPermission({ rolId: role.id, permissionId: permission.permissionId });
    } else {
      removePermission({
        rolId: role.id,
        permissionId: permission.permissionId,
      });
    }
  };

  useEffect(() => {
    if (isChecked) {
      setChecked(isChecked);
    }
  }, [isChecked]);

  return (
    <FormControlLabel
      key={permission.permissionId}
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          sx={{
            color: "#762d70",
            "&.Mui-checked": {
              color: "#762d70",
            },
          }}
        />
      }
      label={permission.description}
    />
  );
};
export default RolePermissionRow;

import React, {
  FunctionComponent,
  useEffect,
  useState,
  useContext,
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";

// Services
import { useAddLogin } from "../services/login";
import { useLocation } from "react-router-dom";

// Types
import { PrivateRouteProps } from "../types/customized";

// Components
import Login from "../views/Login";
import { AdminContext } from "../auth/adminContext";
import Loading from "../shared/Loading";

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: RouteComponent,
}) => {
  const { adminLevel, setAdminLevel } = useContext(AdminContext);
  const [checked, setChecked] = useState(false);
  const { accounts, instance, inProgress, ...rest } = useMsal();
  const [accepted, setAccepted] = useState(false);
  const accessToken = localStorage.getItem("accessToken");
  const location = useLocation();
  const authenticated = useIsAuthenticated();
  const { mutate: login } = useAddLogin((user: any) => {
    setAccepted(user.data.isSuccess);
    if (authenticated && accepted) {
      window.localStorage.setItem(
        "currentAdminId",
        JSON.stringify(user.data.data.user.id)
      );
      window.localStorage.setItem(
        "adminLevel",
        JSON.stringify(user.data.data.user.levelAdmin)
      );
      setAdminLevel(user.data.data.user.levelAdmin);
    }
  });

  const handleRender = () => {
    /* if (location.search.includes("logout")) {
      return <Loading showBack={false} />;
    }
    */
    if (accessToken) {
      return <RouteComponent />;
    }
    if (!authenticated && !accepted && checked && inProgress !== "logout") {
      return <Login />;
    } else {
      return <Loading showBack={false} />;
    }
  };

  useEffect(() => {
    if (location.search.includes("logout")) {
      localStorage.removeItem("accessToken");
      instance.logoutRedirect();
    }
  }, [instance, location.search]);

  useEffect(() => {
    if (inProgress === "none") {
      setTimeout(() => setChecked(true), 1000);
    }
  }, [inProgress]);

  useEffect(() => {
    if (accounts.length) {
      instance
        .acquireTokenSilent({ ...loginRequest, account: accounts[0] })
        .then((response) => {
          localStorage.setItem("accessToken", response.idToken);
          login({ token: response.idToken });
        });
    }
  }, [accessToken, login, authenticated, accepted, accounts, instance]);

  useEffect(() => {
    if (!authenticated) {
      setAccepted(false);
    }
  }, [authenticated]);

  return <>{handleRender()}</>;
};

export default PrivateRoute;

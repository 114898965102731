import React from "react";
import axios from "axios";
import Swal from "sweetalert2";

const http = axios.create({
  baseURL: String(process.env.REACT_APP_ADMIN_DASHBOARD_URL),
});

http.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken && request.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`;
  }
  return request;
});

http.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (err: any) => {
    if (err.response?.status === 401) {
      window.location.href = "/?logout=true";
      throw err;
    }
    return Promise.reject(err);
  }
);

export default http;

import React from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Box,
  Divider,
  Grid,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Components
import AppBox from "../components/AppBox";
import AppContainer from "../components/AppContainer";
import AppSubmitButton from "../components/AppSubmitButton";
import AppCancelButton from "../components/AppCancelButton";

// Services
import { useAddNotificationData } from "../services/notifications";
import { Notifications } from "../types/notifications";
import Loading from "../shared/Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const NotificationsConfiguration = () => {
  const { mutate: createNotification, isLoading } = useAddNotificationData();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Notifications>({
    defaultValues: {
      titleEn: "",
      titleEs: "",
      titlePt: "",
      bodyEn: "",
      bodyEs: "",
      bodyPt: "",
    },
  });
  const onSubmit = (formValues: Notifications) => {
    createNotification({
      id: 0,
      content: [
        {
          culture: "es",
          title: formValues.titleEs,
          body: formValues.bodyEs,
        },
        {
          culture: "en",
          title: formValues.titleEn,
          body: formValues.bodyEn,
        },
        {
          culture: "pt",
          title: formValues.titlePt,
          body: formValues.bodyPt,
        },
      ],
      userId: 0,
      sendImmediately: true,
    });
    reset();
  };

  if (isLoading) {
    return <Loading showBack={true} />;
  }
  return (
    <>
      <AppContainer>
        <AppBox padding="0" width="90%">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="flex-start"
                sx={{ marginBottom: "5px", paddingTop: ".5rem", px: ".5rem" }}
              >
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    width: "100%",
                    color: "#213358",
                    fontWeight: 600,
                    fontSize: "0.875rem",
                  }}
                >
                  Create notification
                </Typography>
              </Grid>
              <Divider sx={{ width: "100%", marginBottom: "15px" }} />
              <Grid container display="flex" sx={{ px: "1rem", pb: "1rem" }}>
                <Grid
                  item
                  xs={4}
                  textAlign="left"
                  sx={{ pl: 0, pt: 0, pb: "1rem", pr: "1rem" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      EN
                    </Typography>
                    <Box sx={{ pb: "1rem" }}>
                      <Box
                        sx={{
                          color: "#8f8f8f",
                          fontSize: ".9rem",
                          marginBottom: "20px",
                        }}
                      >
                        Title
                      </Box>
                      <TextField
                        multiline
                        rows={3}
                        fullWidth
                        label="In English"
                        id="fullWidth"
                        {...register("titleEn", {
                          required: true,
                        })}
                      />
                      {errors?.titleEn?.type === "required" && (
                        <StlyedParagraph>
                          <ErrorRoundedIcon fontSize="small" /> This field is
                          required
                        </StlyedParagraph>
                      )}
                    </Box>
                    <Box
                      sx={{
                        color: "#8f8f8f",
                        fontSize: ".9rem",
                        marginBottom: "20px",
                        marginTop: "30px",
                      }}
                    >
                      Body
                    </Box>
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      label="In English"
                      id="fullWidth"
                      {...register("bodyEn", {
                        required: true,
                      })}
                    />
                    {errors?.bodyEn?.type === "required" && (
                      <StlyedParagraph>
                        <ErrorRoundedIcon fontSize="small" /> This field is
                        required
                      </StlyedParagraph>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  textAlign="left"
                  sx={{ pl: 0, pt: 0, pb: "1rem", pr: "1rem" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      ES
                    </Typography>
                    <Box sx={{ pb: "1rem" }}>
                      <Box
                        sx={{
                          color: "#8f8f8f",
                          fontSize: ".9rem",
                          marginBottom: "20px",
                        }}
                      >
                        Title
                      </Box>
                      <TextField
                        multiline
                        rows={3}
                        fullWidth
                        label="In Spanish"
                        id="fullWidth"
                        {...register("titleEs", {
                          required: true,
                        })}
                      />
                      {errors?.titleEs?.type === "required" && (
                        <StlyedParagraph>
                          <ErrorRoundedIcon fontSize="small" /> This field is
                          required
                        </StlyedParagraph>
                      )}
                    </Box>
                    <Box
                      sx={{
                        color: "#8f8f8f",
                        fontSize: ".9rem",
                        marginBottom: "20px",
                        marginTop: "30px",
                      }}
                    >
                      Body
                    </Box>
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      label="In Spanish"
                      id="fullWidth"
                      {...register("bodyEs", {
                        required: true,
                      })}
                    />
                    {errors?.bodyEs?.type === "required" && (
                      <StlyedParagraph>
                        <ErrorRoundedIcon fontSize="small" /> This field is
                        required
                      </StlyedParagraph>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={4}
                  textAlign="left"
                  sx={{ pl: 0, pt: 0, pb: "1rem" }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                    >
                      PT
                    </Typography>
                    <Box sx={{ pb: "1rem" }}>
                      <Box
                        sx={{
                          color: "#8f8f8f",
                          fontSize: ".9rem",
                          marginBottom: "20px",
                        }}
                      >
                        Title
                      </Box>
                      <TextField
                        multiline
                        rows={3}
                        fullWidth
                        label="In Portuguese"
                        id="fullWidth"
                        {...register("titlePt", {
                          required: true,
                        })}
                      />
                      {errors?.titlePt?.type === "required" && (
                        <StlyedParagraph>
                          <ErrorRoundedIcon fontSize="small" /> This field is
                          required
                        </StlyedParagraph>
                      )}
                    </Box>
                    <Box
                      sx={{
                        color: "#8f8f8f",
                        fontSize: ".9rem",
                        marginBottom: "20px",
                        marginTop: "30px",
                      }}
                    >
                      Body
                    </Box>
                    <TextField
                      multiline
                      rows={5}
                      fullWidth
                      label="In Portuguese"
                      id="fullWidth"
                      {...register("bodyPt", {
                        required: true,
                      })}
                    />
                    {errors?.bodyPt?.type === "required" && (
                      <StlyedParagraph>
                        <ErrorRoundedIcon fontSize="small" /> This field is
                        required
                      </StlyedParagraph>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Box sx={{ width: "100%" }}>
                    <AppCancelButton
                      cancelbtnmargintop="30px"
                      cancelbtnwidth="20%"
                      onClick={() => reset()}
                    >
                      Reset
                    </AppCancelButton>
                    <AppSubmitButton
                      submitbtnmargintop="30px"
                      submitbtnwidth="20%"
                    >
                      Send
                    </AppSubmitButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </AppBox>
      </AppContainer>
    </>
  );
};

export default NotificationsConfiguration;

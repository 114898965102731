import { Container, Stack, styled, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

// Styles
import lost from "../assets/images/404.jpg";

const StyledContainer = styled(Container)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: "calc(100vh - 65px)",
  backgroundColor: "#f1f1f1",
  margin: 0,
  width: "100%",
});
const NotFound = () => {
  return (
    <StyledContainer maxWidth={false}>
      <Stack>
        <Typography variant="h4">Oops, 404 - Not Found!</Typography>
      </Stack>
      <Stack>
        <img src={lost} alt="not found" width={400} height={250} />
      </Stack>
      <Stack>
        <Link to="/">
          <Typography variant="h6">Go to main page</Typography>
        </Link>
      </Stack>
    </StyledContainer>
  );
};
export default NotFound;

import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./axios";
// Types
import { GetAdminPostsResponse } from "../types/adminPosts";

export const useAdminPosts = (page: number) => {
  return useQuery<GetAdminPostsResponse, Error>(
    ["requestAdminPosts", page],
    async () => {
      const res = await http.get<
        "requestAdminPosts",
        { data: GetAdminPostsResponse }
      >("publication/get-all-banner-post?culture=en", {
        params: { page: page, limit: 10 },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

const addAdminPost = (adminPost: FormData) => {
  return http.post(
    `publication/add-banner-post?userId=${adminPost.getAll("userId")}`,
    adminPost
  );
};

export const useAddAdminPostData = () => {
  const queryClient = useQueryClient();
  return useMutation(addAdminPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestAdminPosts"]);
    },
  });
};

const updateAdminPost = (adminPost: FormData) => {
  return http.post(
    `publication/update-banner-post?userId=${adminPost.getAll(
      "userId"
    )}&postId=${adminPost.getAll("postId")}`,
    adminPost
  );
};

export const useUpdateAdminPostData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateAdminPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestAdminPosts"]);
    },
  });
};

import React, { FunctionComponent } from "react";

// Styles
import { Modal, styled } from "@mui/material";

// Types
import { ReusableModalProps } from "../types/customized";

const StyledModal = styled(Modal)((props: ReusableModalProps) => ({
  display: "flex" || props.display,
  justifyContent: "center" || props.modaljustifycontent,
  alignItems: "center" || props.modalalignitems,
}));

const ReusableModal: FunctionComponent<ReusableModalProps> = ({
  children,
  open,
  onClose,
  modaljustifycontent,
  display,
  modalalignitems,
}) => {
  return (
    <StyledModal
      open={open}
      onClose={onClose}
      display={display}
      modaljustifycontent={modaljustifycontent}
      modalalignitems={modalalignitems}
    >
      <>{children}</>
    </StyledModal>
  );
};

export default ReusableModal;

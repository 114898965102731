import React from "react";
import { Container } from "@mui/material";
import Swal from "sweetalert2";
import AppSubmitButton from "../components/AppSubmitButton";
import { downloadExcelService } from "../services/metricsService";

const Metrics = () => {
  const handleMetrics = () => {
    Swal.fire({
      title: `Are you sure?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3db1c8",
      cancelButtonColor: "#cacaca",
      confirmButtonText: "Yes, I'm sure",
      iconColor: "#762d70",
      text: "You will be taken to an external route and you will need your Firebase credentials.",
    }).then((result) => {
      if (result.isConfirmed) {
        window.open("https://console.firebase.google.com/", "_blank")?.focus();
      }
    });
  };

  const handleDownloadExcel = (lang: string) => {
    downloadExcelService(lang).then((res) => {
      const href = URL.createObjectURL(res.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "metrics.xlsx"); // or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };
  return (
    <Container>
      <div className="containerBtn">
        <AppSubmitButton submitbtnwidth="20%" onClick={handleMetrics}>
          View Metrics
        </AppSubmitButton>
        <AppSubmitButton
          submitbtnwidth="20%"
          onClick={() => handleDownloadExcel("es")}
        >
          Download Metrics EN
        </AppSubmitButton>
        <AppSubmitButton
          submitbtnwidth="20%"
          onClick={() => handleDownloadExcel("es")}
        >
          Download Metrics ES
        </AppSubmitButton>
        <AppSubmitButton
          submitbtnwidth="20%"
          onClick={() => handleDownloadExcel("pt")}
        >
          Download Metrics PT
        </AppSubmitButton>
      </div>
    </Container>
  );
};

export default Metrics;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Components
import PostDetail from "../views/PostDetail";
import PostsConfiguration from "../views/PostsConfiguration";
import RolesConfiguration from "../views/RolesConfiguration";
import SpecialtiesConfiguration from "../views/SpecialtiesConfiguration";
import NotFound from "../views/NotFound";
import HashtagsConfiguration from "../views/HashtagsConfiguration";
import CommunitiesConfiguration from "../views/CommunitiesConfiguration";
import CommunityDetail from "../views/CommunityDetail";
import UsersConfiguration from "../views/UsersConfiguration";
import UserDetail from "../views/UserDetail";
import OutletDashboard from "./OutletDashboard";
import AdminPostsConfiguration from "../views/AdminPostsConfiguration";
import AdminPostDetail from "../views/AdminPostDetail";
import { AuthProvider } from "../auth/auth";
import PrivateRoute from "./PrivateRoute";
import NotificationsConfiguration from "../views/NotificationsConfiguration";
import NotificationDetail from "../views/NotificationDetail";
import Metrics from "../views/Metrics";
import AdminLevelValidation from "./AdminLevelValidation";
import Login from "../views/Login";
import Layout from "./Layout";
import { AdminContext } from "../auth/adminContext";
import UserAuthorizations from "../views/UserAuthorizations";

const AppRouter = () => {
  const [adminLevel, setAdminLevel] = useState(0);
  return (
    <BrowserRouter>
      <AuthProvider>
        <AdminContext.Provider value={{ adminLevel, setAdminLevel }}>
          <Routes>
            <Route
              path="/"
              element={<PrivateRoute component={OutletDashboard} />}
            >
              <Route index element={<RolesConfiguration />} />
              <Route path="roles" element={<RolesConfiguration />} />
              <Route
                path="specialties"
                element={<SpecialtiesConfiguration />}
              />
              <Route path="users-posts" element={<PostsConfiguration />} />
              <Route path="post-detail/:postId" element={<PostDetail />} />
              <Route path="admin-posts" element={<AdminPostsConfiguration />} />
              <Route path="metrics" element={<Metrics />} />
              <Route
                path="admin-post-detail/:postId"
                element={<AdminPostDetail />}
              />
              <Route path="*" element={<NotFound />} />
              <Route path="hashtags" element={<HashtagsConfiguration />} />
              <Route
                path="communities"
                element={<CommunitiesConfiguration />}
              />
              <Route
                path="community-detail/:communityId"
                element={<CommunityDetail />}
              />
              <Route
                path="users-authorizations"
                element={<UserAuthorizations />}
              />
              <Route path="users" element={<UsersConfiguration />} />
              <Route path="user-detail/:userId" element={<UserDetail />} />
              <Route
                path="notifications"
                element={<NotificationsConfiguration />}
              />
              <Route
                path="notification-detail/:notificationId"
                element={<NotificationDetail />}
              />
              <Route path="admins" element={<AdminLevelValidation />}></Route>
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </AdminContext.Provider>
      </AuthProvider>
    </BrowserRouter>
  );
};
export default AppRouter;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdminsConfiguration from "../views/AdminsConfiguration";

const AdminLevelValidation = () => {
  const navigate = useNavigate();
  const [adminLevel, setAdminLevel] = useState(0);

  useEffect(() => {
    const getAdminLevel = JSON.parse(localStorage.getItem("adminLevel") || "");
    if (getAdminLevel) {
      setAdminLevel(getAdminLevel);
    }
  }, [adminLevel]);
  if (adminLevel === 2) {
    navigate("/roles");
  }
  if (adminLevel === 1) {
    return <AdminsConfiguration />;
  } else return null;
};

export default AdminLevelValidation;

import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: "#213358",
    },
    secondary: {
      main: "#762d70",
    },
    third: {
      main: "#3bd1c8",
    },
    transparent: {
      main: "#00000000",
    },
  },
});
export default theme;

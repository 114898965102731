import React, { FunctionComponent } from "react";

// Styles
import { Grid, styled } from "@mui/material";

// Types
import { PostCommentProps } from "../types/posts";

const StyledPostCommentDescription = styled("div")({
  fontSize: ".8rem",
  textAlign: "left",
});

const StyledPostCommentDateGrid = styled(Grid)({
  display: "flex",
  fontSize: ".8rem",
});

const StyledPostCommentContainer = styled(Grid)({
  marginTop: "1%",
  marginBottom: "3%",
  padding: ".5rem",
  borderRadius: "30px",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
});

const PostComment: FunctionComponent<PostCommentProps> = ({ postComment }) => {
  let translatedContent: string | undefined = postComment.description;

  const translateHashtags = () => {
    if (postComment.hashtags) {
      postComment.hashtags.forEach((commentHashtag) => {
        translatedContent = translatedContent?.replace(
          commentHashtag.id.toString(),
          commentHashtag.value.toString()
        );
      });
    }
    if (postComment.mentions) {
      postComment.mentions.forEach((commentMention) => {
        translatedContent = translatedContent?.replace(
          commentMention.id.toString(),
          commentMention.value.toString()
        );
      });
    }
  };
  translateHashtags();

  return (
    <StyledPostCommentContainer container spacing={2}>
      <StyledPostCommentDateGrid item xs={9}>
        <div>{postComment.date.substring(0, 10)}</div>
      </StyledPostCommentDateGrid>
      <Grid item xs={3}>
        <div>{postComment.id}</div>
      </Grid>
      <Grid item xs={12}>
        <StyledPostCommentDescription>
          {translatedContent}
        </StyledPostCommentDescription>
      </Grid>
    </StyledPostCommentContainer>
  );
};

export default PostComment;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  IconButton,
  Input,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AddIcon from "@mui/icons-material/Add";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// Services
import { useAddHashtagData, useHashtags } from "../services/hashtags";

// Components
import AppButton from "../components/AppButton";
import AddEditModal from "../shared/AddEditModal";
import HashtagRow from "../shared/HashtagRow";
import AppSubmitButton from "../components/AppSubmitButton";
import HashtagsFilterModal from "../shared/HashtagsFilterModal";
import AppCard from "../components/AppCard";
import AppContainer from "../components/AppContainer";
import Loading from "../shared/Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const HashtagsConfiguration = () => {
  const [hashtag, setHashtag] = useState("");
  const [openHashtagsModal, setOpenHashtagsModal] = useState(false);
  const handleOpenHashtagsModal = () => setOpenHashtagsModal(true);
  const handleCloseHashtagsModal = () => setOpenHashtagsModal(false);
  const [openHashtagsFilterModal, setOpenHashtagsFilterModal] = useState(false);
  const handleOpenHashtagsFilterModal = () => setOpenHashtagsFilterModal(true);
  const handleCloseHashtagsFilterModal = () =>
    setOpenHashtagsFilterModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState(0);
  const { data: hashtags, isLoading } = useHashtags({
    page: currentPage,
    status: status,
    description: hashtag,
  });
  const { mutate: addHashtag, isLoading: hashtagIsLoading } =
    useAddHashtagData(currentPage);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      hashtagDescription: "",
    },
  });

  const handleCloseModal = () => {
    reset();
    handleCloseHashtagsModal();
  };

  useEffect(() => {
    if (status || hashtag) {
      setCurrentPage(1);
    }
  }, [status, hashtag]);

  if (isLoading || hashtagIsLoading) {
    return <Loading showBack={true} />;
  }
  return (
    <>
      <AppContainer>
        <AppCard appcardwidth="40%">
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={4}
                    sx={{ color: "#213358", fontWeight: 600 }}
                  >
                    Hashtags configuration
                    <AppButton
                      tooltiptitle="Add hashtag"
                      onClick={handleOpenHashtagsModal}
                    >
                      <AddIcon sx={{ color: "#213358" }} />
                    </AppButton>
                    <AppButton
                      tooltiptitle="Filters"
                      onClick={handleOpenHashtagsFilterModal}
                    >
                      <FilterAltOutlinedIcon sx={{ color: "#213358" }} />
                    </AppButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: "70%" }} align="left">
                    Hashtag
                  </TableCell>
                  <TableCell align="left" sx={{ width: "30%" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {hashtags?.data.map((hashtag) => (
                  <HashtagRow
                    key={hashtag.id}
                    hashtag={hashtag}
                    currentPage={currentPage}
                  />
                ))}
              </TableBody>
              <TableFooter sx={{ borderTop: "1px solid rgb(224, 224, 224)" }}>
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    {`${currentPage} / ${Math.ceil(
                      (hashtags?.count ?? 0) / 10
                    )}`}
                    <IconButton
                      disabled={hashtags?.count === 0 || currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        hashtags?.count === 0 ||
                        currentPage === Math.ceil((hashtags?.count ?? 0) / 10)
                      }
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AppCard>
      </AppContainer>
      <AddEditModal
        handleClose={() => handleCloseModal()}
        show={openHashtagsModal}
        modalHeader="Add hashtag"
        modalInputLabel="Name"
      >
        <>
          <Input
            placeholder="New hashtag"
            {...register("hashtagDescription", {
              required: true,
            })}
          />
          {errors?.hashtagDescription?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <AppSubmitButton
            onClick={handleSubmit((formValues) => {
              addHashtag({
                description: formValues.hashtagDescription,
              });
              reset();
              handleCloseHashtagsModal();
            })}
          >
            Submit
          </AppSubmitButton>
        </>
      </AddEditModal>
      <HashtagsFilterModal
        open={openHashtagsFilterModal}
        onClose={handleCloseHashtagsFilterModal}
        setStatus={setStatus}
        setHashtag={setHashtag}
      />
    </>
  );
};

export default HashtagsConfiguration;

import http from "./axios";

export const downloadExcelService = (lang: string) => {
  return http.get(`/metrics/get-posts-metrics-as-excel`, {
    params: {
      culture: lang,
    },
    responseType: "blob",
  });
};

import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loading = (props: { showBack: boolean }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        bottom: "0",
        right: "0",
        top: "0",
        left: "0",
        zIndex: 50000,
        backgroundColor: props.showBack ? "rgba(0, 0, 0, 0.5)" : "white",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Loading;

import React, { useEffect, useState } from "react";

// Styles
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

// Components
import AppButton from "../components/AppButton";
import AppCard from "../components/AppCard";
import AppContainer from "../components/AppContainer";

// Types
import { useUsers } from "../services/users";
import UserRow from "../shared/UserRow";
import UsersFilterModal from "../shared/UsersFilterModal";
import Loading from "../shared/Loading";

const UsersConfiguration = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openUsersFilterModal, setOpenUsersFilterModal] = useState(false);
  const handleOpenUsersFilterModal = () => setOpenUsersFilterModal(true);
  const handleCloseUsersFilterModal = () => setOpenUsersFilterModal(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const { data: users, isLoading } = useUsers({
    page: currentPage,
    userName: userName,
    email: email,
  });

  useEffect(() => {
    if (userName || email) {
      setCurrentPage(1);
    }
  }, [userName, email]);

  if (isLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <>
      <AppContainer>
        <AppCard appcardwidth="60%">
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={4}
                    sx={{ color: "#213358", fontWeight: 600 }}
                  >
                    Users configuration
                    <AppButton
                      tooltiptitle="Filters"
                      onClick={handleOpenUsersFilterModal}
                    >
                      <FilterAltOutlinedIcon sx={{ color: "#213358" }} />
                    </AppButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ width: "50%" }} align="left">
                    Name
                  </TableCell>
                  <TableCell sx={{ width: "50%" }} align="left">
                    Email
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.data.map((user) => (
                  <UserRow key={user.id} users={user} />
                ))}
              </TableBody>
              <TableFooter sx={{ borderTop: "1px solid rgb(224, 224, 224)" }}>
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    {`${currentPage} / ${Math.ceil((users?.count ?? 0) / 10)}`}
                    <IconButton
                      disabled={users?.count === 0 || currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        users?.count === 0 ||
                        currentPage === Math.ceil((users?.count ?? 0) / 10)
                      }
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AppCard>
      </AppContainer>
      <UsersFilterModal
        handleClose={handleCloseUsersFilterModal}
        show={openUsersFilterModal}
        setUserName={setUserName}
        setEmail={setEmail}
      />
    </>
  );
};

export default UsersConfiguration;

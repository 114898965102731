import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./axios";
import Swal from "sweetalert2";

// Types
import {
  GetUserDataUnauthorizedResponse,
  GetUsersAuthorizedResponse,
  GetUsersUnauthorizedResponse,
} from "../types/users";

// GET USER AUTHORIZED
export const useUsersAuthorize = (props: { page: number }) => {
  return useQuery<GetUsersAuthorizedResponse, Error>(
    ["requestUsersAuthorized", props.page],
    async () => {
      const res = await http.get<
        "requestUsersAuthorized",
        { data: GetUsersAuthorizedResponse }
      >("/pre-authorized/get-all", {
        params: {
          page: props.page || 1,
        },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

// GET USER UNAUTHORIZED
export const useUsersUnauthorized = (props: { page: number }) => {
  return useQuery<GetUsersUnauthorizedResponse, Error>(
    ["requestUsersUnauthorized", props.page],
    async () => {
      const res = await http.get<
        "requestUsersUnauthorized",
        { data: GetUsersUnauthorizedResponse }
      >("/unauthorized/get-all-unauthorized-users", {
        params: {
          page: props.page || 1,
        },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

// GET DATA USER UNAUTHORIZED

export const getUserUnauthorizedData = (idUser: number) => {
  return http.get<GetUserDataUnauthorizedResponse>(
    `/unauthorized/get-user-detail-by-id`,
    {
      params: { id: idUser, culture: "en" },
    }
  );
};

export const useGetUserUnauthorizedData = () => {
  const queryClient = useQueryClient();
  return useMutation(getUserUnauthorizedData, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestUsersUnauthorized");
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please try again.",
        iconColor: "#942a2ab5",
        confirmButtonColor: "#942a2ab5",
      });
    },
  });
};

// ADD USER
const addUserAuthorized = (email: { email: string }) => {
  return http.post(`/pre-authorized/add`, email);
};

export const useAddUserAuthorizedData = () => {
  const queryClient = useQueryClient();
  return useMutation(addUserAuthorized, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestUsersAuthorized");
      queryClient.invalidateQueries("requestUsersUnauthorized");
      Swal.fire({
        icon: "success",
        title: "New Authorized User Successfully",
        showConfirmButton: true,
        confirmButtonColor: "#004e92",
        iconColor: "#004e92",
      });
    },
    onError: (error) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please try again.",
        iconColor: "#942a2ab5",
        confirmButtonColor: "#942a2ab5",
      });
    },
  });
};

// DELETE USER
const removeUserAuthorizated = (id: number) => {
  return http.delete(`pre-authorized/delete/${id}`);
};

export const useRemoveUserAuthorizated = () => {
  const queryClient = useQueryClient();
  return useMutation(removeUserAuthorizated, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("requestUsersAuthorized");
      Swal.fire({
        icon: "success",
        title: "The user was deleted",
        showConfirmButton: true,
        confirmButtonColor: "#004e92",
        iconColor: "#004e92",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please try again.",
        iconColor: "#942a2ab5",
        confirmButtonColor: "#942a2ab5",
      });
    },
  });
};

// UPDATE
const updateUserAuthorizated = (dataUser: { id: number; email: string }) => {
  return http.patch(`pre-authorized/update/${dataUser.id}`, {
    email: dataUser.email,
  });
};

export const useUpdateUserPreauthorized = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUserAuthorizated, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("requestUsersAuthorized");
      Swal.fire({
        icon: "success",
        title: "The email was updated successfully",
        showConfirmButton: true,
        confirmButtonColor: "#004e92",
        iconColor: "#004e92",
      });
    },
    onError: () => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please try again.",
        iconColor: "#942a2ab5",
        confirmButtonColor: "#942a2ab5",
      });
    },
  });
};

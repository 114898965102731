import React from "react";
import { Link } from "react-router-dom";

// Styles
import { Button, TableCell, TableRow, styled } from "@mui/material";

// Types
import { AppTableRowProps } from "../types/posts";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const AppTableRow = ({ post }: AppTableRowProps) => {
  return (
    <>
      <StyledRow>
        <TableCell
          align="center"
          colSpan={6}
          sx={{ width: "10%", wordWrap: "anywhere" }}
        >
          <Link to={`/post-detail/${post.id}`}>
            <Button color="primary" variant="text">
              {post.id}
            </Button>
          </Link>
        </TableCell>
        <TableCell
          align="left"
          colSpan={3}
          sx={{ width: "40%", wordWrap: "anywhere" }}
        >
          {post.userName}
        </TableCell>
        <TableCell
          align="left"
          colSpan={3}
          sx={{ width: "15%", wordWrap: "anywhere" }}
        >
          {post.postDate.substring(0, 10)}
        </TableCell>
        <TableCell
          align="center"
          colSpan={3}
          sx={{ width: "15%", wordWrap: "anywhere" }}
        >
          {post.reportsCount}
        </TableCell>
        <TableCell
          align="center"
          colSpan={3}
          sx={{ width: "20%", wordWrap: "anywhere" }}
        >
          {post.isBlocked ? "Blocked" : "Active"}
        </TableCell>
      </StyledRow>
    </>
  );
};
export default AppTableRow;

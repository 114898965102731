import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Components
import AppTableCard from "../shared/AppTableCard";
import AddEditCommunityModal from "../shared/AddEditCommunityModal";
import AppContainer from "../components/AppContainer";
import CommunityRow from "../shared/CommunityRow";
import AppSubmitButton from "../components/AppSubmitButton";

// Styles
import AppCancelButton from "../components/AppCancelButton";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  styled,
  Typography,
} from "@mui/material";

// Services
import { useAddCommunityData, useCommunities } from "../services/communities";

// Types
import { AddEditCommunityModalFormProps } from "../types/customized";
import Loading from "../shared/Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const CommunitiesConfiguration = () => {
  const [openCommunitiesModal, setOpenCommunitiesModal] = useState(false);
  const handleOpenCommunitiesModal = () => setOpenCommunitiesModal(true);
  const handleCloseCommunitiesModal = () => setOpenCommunitiesModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: communities, isLoading } = useCommunities({
    page: currentPage,
  });
  const { mutate: addCommunity, isLoading: isAddingCommunity } =
    useAddCommunityData();
  const [pictureName, setPictureName] = useState<string | null>(
    "Choose an image file"
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddEditCommunityModalFormProps>({
    defaultValues: {
      key: "",
      nameEn: "",
      nameEs: "",
      namePt: "",
      descriptionEn: "",
      descriptionEs: "",
      descriptionPt: "",
      photo: [],
      id: null,
    },
  });
  const handleCloseModal = () => {
    handleCloseCommunitiesModal();
    reset();
    setPictureName("Choose an image file");
    setPictureName("Choose an image file");
  };

  // useEffect(() => {
  //   if (communities?.data.length === 0) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [currentPage, communities]);

  if (isLoading || isAddingCommunity) {
    return <Loading showBack={true} />;
  }
  return (
    <>
      <AppContainer>
        <AppTableCard
          headerName="Communities configuration"
          headerEn="Es"
          headerEs="En"
          headerPt="Pt"
          itemsActions="Actions"
          data={communities}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          tooltipTitle="Add Community"
          handleOpenRolesModal={handleOpenCommunitiesModal}
        >
          {communities?.data.map((community) => (
            <CommunityRow
              key={community.id}
              community={community}
              currentPage={currentPage}
            />
          ))}
        </AppTableCard>
      </AppContainer>
      <AddEditCommunityModal
        handleClose={() => handleCloseModal()}
        show={openCommunitiesModal}
        modalHeader="Add community"
      >
        <Grid item xs={12} display="flex">
          <Grid
            item
            xs={4}
            textAlign="left"
            sx={{ pl: 0, pt: 0, pb: "1rem", pr: "1rem" }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                EN
              </Typography>
              <Box sx={{ pb: "1rem" }}>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                <Input
                  placeholder="In English"
                  {...register("nameEn", {
                    required: true,
                    maxLength: 150,
                  })}
                />
                {errors?.nameEn?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.nameEn?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 150 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Box>
              <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                Description
              </Box>
              <Input
                placeholder="In English"
                {...register("descriptionEn", {
                  required: true,
                  maxLength: 300,
                })}
              />
              {errors?.descriptionEn?.type === "required" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> This field is required
                </StlyedParagraph>
              )}
              {errors?.descriptionEn?.type === "maxLength" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> Max 300 characters
                  allowed.
                </StlyedParagraph>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            textAlign="left"
            sx={{ pl: 0, pt: 0, pb: "1rem", pr: "1rem" }}
          >
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                ES
              </Typography>
              <Box sx={{ pb: "1rem" }}>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                <Input
                  placeholder="In Spanish"
                  {...register("nameEs", {
                    required: true,
                    maxLength: 150,
                  })}
                />
                {errors?.nameEs?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.nameEs?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 150 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Box>
              <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                Description
              </Box>
              <Input
                placeholder="In Spanish"
                {...register("descriptionEs", {
                  required: true,
                  maxLength: 300,
                })}
              />
              {errors?.descriptionEs?.type === "required" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> This field is required
                </StlyedParagraph>
              )}
              {errors?.descriptionEs?.type === "maxLength" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> Max 300 characters
                  allowed.
                </StlyedParagraph>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4} textAlign="left" sx={{ pl: 0, pt: 0, pb: "1rem" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom component="div">
                PT
              </Typography>
              <Box sx={{ pb: "1rem" }}>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                <Input
                  placeholder="In Portuguese"
                  {...register("namePt", {
                    required: true,
                    maxLength: 150,
                  })}
                />
                {errors?.namePt?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.namePt?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 150 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Box>
              <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                Description
              </Box>
              <Input
                placeholder="In Portuguese"
                {...register("descriptionPt", {
                  required: true,
                  maxLength: 300,
                })}
              />
              {errors?.descriptionPt?.type === "required" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> This field is required
                </StlyedParagraph>
              )}
              {errors?.descriptionPt?.type === "maxLength" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> Max 300 characters
                  allowed.
                </StlyedParagraph>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Button
            component="span"
            sx={{
              width: "100%",
              cursor: "pointer",
              fontSize: ".9rem",
              padding: ".2rem",
              backgroundColor: "#e9ecef",
              color: "#213358",
              borderRadius: "10px",
              marginBottom: "1rem",
            }}
          >
            <label>
              <span>{`${pictureName}`}</span>
              <Input
                style={{ display: "none" }}
                inputProps={{ accept: "image/*" }}
                type="file"
                {...register("photo", {
                  onChange: (e) => {
                    if (e.target.files[0].name.length > 50) {
                      let name = e.target.files[0].name.substring(0, 50);
                      name = name + ` (...)`;
                      setPictureName(name);
                    } else setPictureName(e.target.files[0].name);
                  },
                })}
              />
            </label>
          </Button>
        </Grid>
        <Divider sx={{ width: "100%" }} />
        <Grid item xs={12} textAlign="end">
          <AppCancelButton onClick={() => handleCloseModal()}>
            Cancel
          </AppCancelButton>
          <AppSubmitButton
            submitbtnwidth="20%"
            onClick={handleSubmit((formValues) => {
              const formData = new FormData();
              formData.append(
                "request",
                JSON.stringify({
                  culture: "es",
                  name: formValues.nameEs,
                  description: formValues.descriptionEs,
                })
              );
              formData.append(
                "request",
                JSON.stringify({
                  culture: "en",
                  name: formValues.nameEn,
                  description: formValues.descriptionEn,
                })
              );
              formData.append(
                "request",
                JSON.stringify({
                  culture: "pt",
                  name: formValues.namePt,
                  description: formValues.descriptionPt,
                })
              );
              formData.append("file", formValues.photo[0]);
              // formDataEs.append("key", formValues.nameEs);
              // formDataEs.append("name", formValues.nameEs);
              // formDataEs.append("description", formValues.descriptionEs);
              // formDataEs.append("culture", "es");
              // formDataEs.append("files", formValues.photo[0]);
              // const formDataEn = new FormData();
              // formDataEn.append("key", formValues.nameEs);
              // formDataEn.append("name", formValues.nameEn);
              // formDataEn.append("description", formValues.descriptionEn);
              // formDataEn.append("culture", "en");
              // formDataEn.append("files", formValues.photo[0]);
              // const formDataPt = new FormData();
              // formDataPt.append("key", formValues.nameEs);
              // formDataPt.append("name", formValues.namePt);
              // formDataPt.append("description", formValues.descriptionPt);
              // formDataPt.append("culture", "pt");
              // formDataPt.append("files", formValues.photo[0]);
              addCommunity(formData);
              // addCommunity(formDataEn);
              // addCommunity(formDataPt);
              handleCloseModal();
            })}
          >
            Submit
          </AppSubmitButton>
        </Grid>
      </AddEditCommunityModal>
    </>
  );
};

export default CommunitiesConfiguration;

import React from "react";
import { Box, Divider, Grid, styled, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

// Services
import { useAdminPosts } from "../services/adminPosts";
import AppCarousel from "../shared/AppCarousel";

// Types
import { AdminPost } from "../types/adminPosts";

// Componnets
import AppContainer from "../components/AppContainer";
import Loading from "../shared/Loading";

const StyledGrid = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "10px",
  maxHeight: "83vh",
  padding: "0",
  marginLeft: "10%",
  marginRight: "10%",
  minHeight: "30vh",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.20),0px 1px 3px 0px rgba(0,0,0,0.12);",
});

const StyledPostDetailIdBox = styled(Box)({
  fontSize: "1rem",
  textAlign: "left",
  display: "flex",
  justifyContent: "flex-start",
});
const AdminPostDetail = () => {
  const params = useParams<{ postId: string }>();
  const { data: adminPosts, isLoading } = useAdminPosts(1);
  const findAdminPost = adminPosts?.data.find(
    (post: AdminPost) => post.id.toString() === params.postId
  );
  let translatedContent: string | undefined = findAdminPost?.content;

  const translateHashtags = () => {
    if (findAdminPost?.hashtags) {
      findAdminPost?.hashtags.forEach((postHashtag) => {
        translatedContent = translatedContent?.replace(
          postHashtag.id.toString(),
          postHashtag.value.toString()
        );
      });
    }
    if (findAdminPost?.mentions) {
      findAdminPost?.mentions.forEach((mention) => {
        translatedContent = translatedContent?.replace(
          mention.id.toString(),
          mention.value.toString()
        );
      });
    }
  };
  translateHashtags();

  if (isLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <AppContainer>
      <StyledGrid container spacing={0}>
        <Grid item xs={12} display="flex" sx={{ paddingLeft: 0 }}>
          {findAdminPost && findAdminPost.postFiles.length > 0 && (
            <Grid item xs={7} display="flex" flexDirection="column">
              <AppCarousel images={findAdminPost?.postFiles} />
            </Grid>
          )}
          <Divider orientation="vertical" />
          <Grid
            container
            xs={findAdminPost && findAdminPost?.postFiles.length > 0 ? 5 : 12}
            display="flex"
            flexDirection="row"
            padding="1.3rem"
            alignContent="flex-start"
          >
            <Grid
              item
              xs={8}
              sx={{
                justifyContent: "flex-start",
                display: "flex",
              }}
            >
              {findAdminPost?.communityName && (
                <>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#8c8c8c",
                      paddingRight: "1%",
                      fontSize: ".8rem",
                    }}
                  >
                    {"Publicated in "}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#3db1c8",
                      fontWeight: 500,
                      fontSize: ".8rem",
                    }}
                  >
                    {findAdminPost?.communityName}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <StyledPostDetailIdBox>{findAdminPost?.id}</StyledPostDetailIdBox>
            </Grid>
            <Divider style={{ width: "100%" }} />
            <Grid item xs={12} display="flex" alignItems="center">
              <Box sx={{ fontSize: ".8rem", color: "#8f8f8f" }}>
                {findAdminPost?.postDate.substring(0, 10)}
              </Box>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Box
                sx={{
                  maxHeight:
                    findAdminPost && findAdminPost?.postFiles.length > 0
                      ? "43vh"
                      : "25vh",
                  overflowY: "auto",
                  textAlign: "initial",
                  marginTop: "5%",
                  fontSize: ".9rem",
                }}
              >
                {findAdminPost?.title}
              </Box>
            </Grid>
            <Grid item xs={12} display="flex">
              <Box
                sx={{
                  maxHeight:
                    findAdminPost && findAdminPost?.postFiles.length > 0
                      ? "43vh"
                      : "25vh",
                  overflowY: "auto",
                  textAlign: "initial",
                  marginTop: "5%",
                  fontSize: ".9rem",
                }}
              >
                {translatedContent}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
      </StyledGrid>
    </AppContainer>
  );
};

export default AdminPostDetail;

import React, { FunctionComponent } from "react";
import { RoleModalProps } from "../types/roles";
import { useForm } from "react-hook-form";

// Styles
import {
  Typography,
  styled,
  FormLabel,
  Stack,
  FormGroup,
  IconButton,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// Components
import AppModal2 from "../components/AppModal2";
import AppBox from "../components/AppBox";

const StyledForm = styled(FormGroup)({
  bgcolor: "red",
});

const StyledFormLabel = styled(FormLabel)({
  marginTop: 0,
});

const StyledStack = styled(Stack)({
  width: "100%",
});

const AddEditModal: FunctionComponent<RoleModalProps> = ({
  show,
  handleClose,
  modalHeader,
  children,
  modalInputLabel,
}) => {
  return (
    <AppModal2 open={show} onClose={handleClose}>
      <AppBox>
        <StyledForm
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px",
          }}
        >
          <StyledStack direction={"row"}>
            <Typography mb={3} flex={8} variant="h5" component="div">
              {modalHeader}
            </Typography>
            <IconButton sx={{ height: "30px" }} onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </StyledStack>
          <StyledStack>
            <StyledFormLabel>{modalInputLabel}</StyledFormLabel>
            {children}
          </StyledStack>
        </StyledForm>
      </AppBox>
    </AppModal2>
  );
};

export default AddEditModal;

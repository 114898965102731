import React from "react";
// Styles
import { Card, CardMedia, Container, Grid } from "@mui/material";
import logo from "../assets/images/BlueLogo.png";
// Components
import { SignInButton } from "../components/SignInButton";

const Login = () => {
  return (
    <Container
      maxWidth={false}
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f1f1f1",
      }}
    >
      <Card
        sx={{
          width: "25%",
          height: "auto",
          borderRadius: "15px",
          padding: "1.5rem",
        }}
      >
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <CardMedia
              component="img"
              image={logo}
              alt="icon"
              sx={{
                width: "50%",
              }}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <SignInButton />
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Login;

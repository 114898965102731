import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

// Styles

import { Button, styled, TableCell, TableRow, Tooltip } from "@mui/material";

// Types

import { UserRowProps } from "../types/users";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const UserRow: FunctionComponent<UserRowProps> = ({ users }) => {
  return (
    <>
      <StyledRow
        key={users.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          sx={{ width: "50%", wordWrap: "anywhere" }}
          align="left"
          scope="row"
        >
          <Link to={`/user-detail/${users.id}`}>
            <Tooltip title="View detail" placement="top" arrow>
              <Button
                color="primary"
                variant="text"
                sx={{
                  textAlign: "left",
                  textTransform: "none",
                  padding: 0,
                }}
              >
                {users.fullName}
              </Button>
            </Tooltip>
          </Link>
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "50%" }}
        >
          {users.email}
        </TableCell>
      </StyledRow>
    </>
  );
};

export default UserRow;

import { useMutation, useQueryClient, useQuery } from "react-query";
import http from "./axios";

// Types
import { GetSpecialtiesResponse } from "../types/specialties";

// Get
export const useSpecialties = (page: number) => {
  return useQuery<GetSpecialtiesResponse, Error>(
    ["requestSpecialties", page],
    async () => {
      const res = await http.get<
        "requestSpecialties",
        { data: GetSpecialtiesResponse }
      >("medical-speciality/get-all-medical-speciality-with-languaje", {
        params: { page: page, limit: 10 },
      });
      return res.data;
    }
  );
};

// Post add
const addSpecialty = (
  specialty: { description: string; culture: string }[]
) => {
  return http.post(`medical-speciality/add`, specialty);
};

export const useAddSpecialtyData = () => {
  const queryClient = useQueryClient();
  return useMutation(addSpecialty, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestSpecialties");
    },
  });
};

// Post update
const updateSpecialty = (specialty: {
  id: number;
  description: string;
  culture: string;
}) => {
  return http.post(`medical-speciality/edit/${specialty.culture}`, specialty);
};

export const useUpdateSpecialtyData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSpecialty, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestSpecialties");
    },
  });
};

// Delete
const removeSpecialty = (specialty: { id: number }) => {
  return http.delete("medical-speciality/delete", { data: specialty });
};

export const useDeleteSpecialty = (page: number) => {
  const queryClient = useQueryClient();
  return useMutation(removeSpecialty, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestSpecialties", page]);
    },
  });
};

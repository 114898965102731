import React, { FunctionComponent } from "react";

// Styled
import { Button, styled } from "@mui/material";

// Types
import { AppSubmitButtonProps } from "../types/customized";

const StyledSubmitButton = styled(Button)((props: AppSubmitButtonProps) => ({
  marginTop: props.submitbtnmargintop || "4%",
  background:
    props.submitButtonBackground ||
    "linear-gradient(to right, #213358, #3db1c8)",
  borderRadius: props.submitButtonBorderRadius || "15px",
  width: props.submitbtnwidth || "100%",
}));
const AppSubmitButton: FunctionComponent<AppSubmitButtonProps> = ({
  submitbtnmargintop,
  submitbtnwidth,
  children,
  disable,
  onClick,
  id,
}) => {
  return (
    <StyledSubmitButton
      onClick={onClick}
      variant="contained"
      type="submit"
      submitbtnwidth={submitbtnwidth}
      submitbtnmargintop={submitbtnmargintop}
      disabled={disable}
      id={id}
    >
      {children}
    </StyledSubmitButton>
  );
};

export default AppSubmitButton;

import { useMutation } from "react-query";
import Swal from "sweetalert2";
import { NotificationAll } from "../types/notifications";
import http from "./axios";

// create notification
const addNotification = (notification: NotificationAll) => {
  return http.post(`custom-notification/add-notification`, notification);
};

export const useAddNotificationData = () => {
  return useMutation(addNotification, {
    onSuccess: () => {
      Swal.fire({
        title: "Sent!",
        text: "The notification has been sent",
        icon: "success",
        confirmButtonColor: "#3db1c8",
        confirmButtonText: "Ok",
        iconColor: "#762d70",
      });
    },
  });
};

import React, { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  RadioGroup,
  styled,
  Typography,
  Box,
  Radio,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Types
import { FilterModalFormProps, FilterModalProps } from "../types/posts";

// Components
import AppCancelButton from "./AppCancelButton";
import AppSubmitButton from "./AppSubmitButton";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const StlyedParagraph = styled("p")({
  color: "#762d70",
});
const FilterModal: FunctionComponent<FilterModalProps> = ({
  open,
  handleClose,
  setPostId,
  setPostUserName,
  setPostDate,
  setPostOrder,
  setPostStatus,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FilterModalFormProps>({
    defaultValues: {
      postId: undefined,
      userName: "",
      date: undefined,
      blocked: undefined,
      order: 1,
    },
  });

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  return (
    <Container>
      <StyledModal
        open={open}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          width={500}
          height="auto"
          bgcolor="white"
          p={3}
          borderRadius={5}
          padding={4}
        >
          <FormGroup
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "2px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Typography flex={8} variant="h5" component="div">
                  Filters{" "}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => handleCloseModal()}>
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item xs={2} alignSelf="center">
                <FormLabel>Post id</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <Input
                  {...register("postId", { pattern: /^[0-9 ]*[0-9][0-9 ]*$/i })}
                />
                {errors?.postId?.type === "pattern" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field should be
                    numerical.
                  </StlyedParagraph>
                )}
              </Grid>
              <Grid item xs={2} alignSelf="center">
                <FormLabel>User name</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <Input {...register("userName")} />
              </Grid>
              <Grid item xs={2} alignSelf="center">
                <FormLabel>Date</FormLabel>
              </Grid>
              <Grid item xs={10}>
                <Input type="date" {...register("date")} />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Status
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="Active"
                      {...register("blocked")}
                    />
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Blocked"
                      {...register("blocked")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Reports Number
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Descendent"
                      {...register("order")}
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="Ascendent"
                      {...register("order")}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Divider />
              <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
                <AppCancelButton onClick={() => handleCloseModal()}>
                  Cancel
                </AppCancelButton>
                <AppSubmitButton
                  submitbtnwidth="20%"
                  onClick={handleSubmit((data) => {
                    setPostId(data.postId);
                    setPostUserName(data.userName);
                    setPostDate(data.date);
                    setPostOrder(data.order);
                    setPostStatus(data.blocked);
                    reset();
                    handleClose();
                  })}
                >
                  Apply
                </AppSubmitButton>
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
      </StyledModal>
    </Container>
  );
};

export default FilterModal;

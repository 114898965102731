import React, { FunctionComponent } from "react";

// Components
import AppModal2 from "../components/AppModal2";
import AppBox from "../components/AppBox";

// Types
import { AddEditCommunityModalProps } from "../types/customized";

// Styles
import { Grid, IconButton, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const AddEditCommunityModal: FunctionComponent<AddEditCommunityModalProps> = ({
  children,
  show,
  handleClose,
  modalHeader,
}) => {
  return (
    <AppModal2 open={show} onClose={handleClose}>
      <AppBox width="40%" padding="1.5rem">
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              sx={{ paddingLeft: "1rem" }}
            >
              {modalHeader}
            </Typography>
            <IconButton sx={{ height: "30px" }} onClick={handleClose}>
              <CloseRoundedIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} textAlign="left" sx={{ padding: "1rem" }}>
            {children}
          </Grid>
        </Grid>
      </AppBox>
    </AppModal2>
  );
};

export default AddEditCommunityModal;

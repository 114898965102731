import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./axios";

// Types
import {
  GetCommunitiesResponse,
  GetCommunityDetailResponse,
} from "../types/communities";

// Get
export const useCommunities = (props?: { page?: number; limit?: number }) => {
  return useQuery<GetCommunitiesResponse, Error>(
    ["requestCommunities", props?.page],
    async () => {
      const res = await http.get<
        "requestCommunities",
        { data: GetCommunitiesResponse }
      >("community/get-all-with-languaje", {
        params: { page: props?.page, limit: props?.limit || 10 },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

// Get Detail
export const useCommunityDetail = (communityId?: string | number) => {
  return useQuery<GetCommunityDetailResponse, Error>(
    ["requestCommunityDetail", communityId],
    async () => {
      const res = await http.get<
        "requestPostDetail",
        { data: GetCommunityDetailResponse }
      >("community/get-post-by-community", {
        params: { communityId: communityId },
      });
      if (!res.data.isSuccess) {
        throw new Error(res.data.message);
      }
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
      retry: false,
    }
  );
};

// Post add
const addCommunity = (community: FormData) => {
  return http.post(`community/add/`, community);
};

export const useAddCommunityData = () => {
  const queryClient = useQueryClient();
  return useMutation(addCommunity, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestCommunities");
    },
  });
};

// Post update
const updateCommunity = (community: FormData) => {
  return http.post(`community/edit/${community.getAll("culture")}`, community);
};

export const useUpdateCommunityData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateCommunity, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestCommunities");
    },
  });
};

const removeCommunity = (community: { id: number }) => {
  return http.delete("community/delete", { data: community });
};

export const useDeleteCommunity = () => {
  const queryClient = useQueryClient();
  return useMutation(removeCommunity, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestCommunities");
    },
  });
};

import React, { FunctionComponent } from "react";

// Styles
import { Card, Grid, styled, Typography } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";

// Types
import { PostReportProps } from "../types/posts";

const StyledPostReportCard = styled(Grid)({
  padding: "1.5rem",
  display: "flex",
  alignItems: "center",
});

const StyledPostReportData = styled(Grid)({
  display: "flex",
});

const StyledPostReportUsername = styled(Typography)({
  fontSize: "1.1rem",
  fontWeight: "bold",
});

const StyledPostReportReason = styled(Grid)({
  fontSize: 13,
  color: "#3db1c8",
  justifyContent: "center",
  display: "flex",
});

const PostReport: FunctionComponent<PostReportProps> = ({ postReport }) => {
  return (
    <Card sx={{ minWidth: 275, marginBottom: "3%", borderRadius: "30px" }}>
      <StyledPostReportCard container>
        <StyledPostReportData item xs={8}>
          <StyledPostReportUsername gutterBottom>
            {postReport.fullName}
          </StyledPostReportUsername>
        </StyledPostReportData>
        <StyledPostReportReason item xs={4} alignItems="center">
          <ReportIcon fontSize="small" color="primary" />
          {postReport.reportReason}
        </StyledPostReportReason>
        <StyledPostReportData item xs={12} sx={{ fontSize: 13 }}>
          {postReport.usuario}
        </StyledPostReportData>
      </StyledPostReportCard>
    </Card>
  );
};

export default PostReport;

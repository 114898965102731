import React, { useState } from "react";

// Styles
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  IconButton,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AddIcon from "@mui/icons-material/Add";

// Components
import AdminPostsRow from "../shared/AdminPostsRow";
import AddEditAdminPostModal from "../shared/AddEditAdminPostModal";
import AppContainer from "../components/AppContainer";

// Services
import { useAdminPosts } from "../services/adminPosts";
import AppCard from "../components/AppCard";
import AppButton from "../components/AppButton";
import Loading from "../shared/Loading";

const AdminPostsConfiguration = () => {
  const [openAddAdminPostModal, setOpenAddAdminPostModal] = useState(false);
  const handleOpenAddAdminPostModal = () => setOpenAddAdminPostModal(true);
  const handleCloseAddAdminPostModal = () => setOpenAddAdminPostModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: adminPosts, isLoading } = useAdminPosts(currentPage);

  if (isLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <>
      <AppContainer containerPaddingTop="2%">
        <AppCard appcardwidth="70%">
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ color: "#213358", fontWeight: 600 }}
                  >
                    Admin posts configuration
                    <AppButton
                      tooltiptitle="Add post banner"
                      onClick={handleOpenAddAdminPostModal}
                    >
                      <AddIcon sx={{ color: "#213358" }} />
                    </AppButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    Id
                  </TableCell>
                  <TableCell align="left" colSpan={3}>
                    Title
                  </TableCell>
                  <TableCell align="left" colSpan={3}>
                    Date
                  </TableCell>
                  <TableCell align="left" colSpan={3}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adminPosts?.data.map((post) => (
                  <AdminPostsRow key={post.id} adminPost={post} />
                ))}
              </TableBody>
              <TableFooter sx={{ width: "100%" }}>
                <TableRow>
                  <TableCell align="right" colSpan={18}>
                    {`${currentPage} / ${Math.ceil(
                      (adminPosts?.count ?? 0) / 10
                    )}`}
                    <IconButton
                      disabled={adminPosts?.count === 0 || currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        adminPosts?.count === 0 ||
                        currentPage === Math.ceil((adminPosts?.count ?? 0) / 10)
                      }
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AppCard>
      </AppContainer>
      <AddEditAdminPostModal
        handleClose={handleCloseAddAdminPostModal}
        show={openAddAdminPostModal}
      ></AddEditAdminPostModal>
    </>
  );
};

export default AdminPostsConfiguration;

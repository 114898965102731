import React, { useState } from "react";

// Components
import AppContainer from "../components/AppContainer";
import AppSubmitButton from "../components/AppSubmitButton";

import {
  AppBar,
  Box,
  Grid,
  styled,
  Input,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tab,
  Tabs,
} from "@mui/material";

// Icons
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

// Services
import {
  useAddUserAuthorizedData,
  useUsersAuthorize,
  useUsersUnauthorized,
} from "../services/userAuthorize";
import UserAuthorizedRow from "./UserAuthorizedRow";
import Loading from "../shared/Loading";
import UserUnauthorizedRow from "./UserUnauthorizedRow";

// Styles
const StyledGrid = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "20px",
  height: "auto",
  padding: "0",
  width: "80%",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.20),0px 1px 3px 0px rgba(0,0,0,0.12);",
});

const StyledParagraph = styled(Box)({
  color: "red",
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
});

const SectionEmail = styled(Box)({
  display: "flex",
  alignItems: "flex-start",
  width: "675px",
});

const EmailContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minWidth: "400px",
  marginRight: "30px",
});

const ContainerTable = styled(TableContainer)({
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

const SubcontainerTable = styled(Table)({
  width: "70%",
  marginTop: "30px",
});

const SubcontainerTableUnauthorized = styled(Table)({
  width: "90%",
  marginTop: "10px",
});

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="wFull"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserAuthorizations() {
  const [value, setValue] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [maxCharacter, setMaxCharacter] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [currentPageAuthorized, setCurrentPageAuthorized] = useState(1);
  const { data: userAuthorized, isLoading } = useUsersAuthorize({
    page: currentPageAuthorized,
  });

  const [currentPageUnauthorized, setCurrentPageUnauthorized] = useState(1);
  const { data: usersUnauthorized } = useUsersUnauthorized({
    page: currentPageUnauthorized,
  });

  const { mutate: addUserAuthorized, isLoading: isAddingUserAuthorized } =
    useAddUserAuthorizedData();

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const regex =
      /^\s*[a-zA-Z0-9._-]+[@][a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,7}\s*$/;
    const isValid = regex.test(inputValue);

    if (inputValue.length >= 100) {
      setMaxCharacter(true);
      setErrorText("Character limit exceeded");
    } else {
      setMaxCharacter(false);
      setEmail(inputValue);
      setErrorText("");
      if (isValid) {
        setDisableBtn(false);
      } else {
        setDisableBtn(true);
      }
    }
  };

  const handleValidation = () => {
    addUserAuthorized({ email: email });
    setEmail("");
    setDisableBtn(true);
  };

  if (isLoading || isAddingUserAuthorized) {
    return <Loading showBack={true} />;
  }
  return (
    <AppContainer containerPaddingTop="2%">
      <StyledGrid
        container
        spacing={0}
        alignContent="flex-start"
        sx={{ overflow: "hidden" }}
        id="containerUserPreauthorized"
      >
        <AppBar position="static" color="transparent">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs user authorization"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Authorized Users" {...a11yProps(0)} />
            <Tab label="Authorizations to be validated" {...a11yProps(1)} />
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <SectionEmail>
            <EmailContent>
              <Input
                value={email}
                onChange={(e: any) => handleEmailChange(e)}
                type="email"
                error={maxCharacter}
                placeholder="Email"
                fullWidth
              />
              {maxCharacter && (
                <StyledParagraph>
                  <ErrorRoundedIcon fontSize="small" /> {errorText}
                </StyledParagraph>
              )}
            </EmailContent>
            <AppSubmitButton
              submitbtnmargintop="0px"
              onClick={handleValidation}
              disable={disableBtn}
              id="buttonPreAuthorized"
            >
              Pre-authorize new email
            </AppSubmitButton>
          </SectionEmail>

          <ContainerTable>
            <SubcontainerTable size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ color: "#213358", fontWeight: 600 }}
                  >
                    Authorized users
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={1} sx={{ width: "10%" }}>
                    Id
                  </TableCell>
                  <TableCell align="left" colSpan={1} sx={{ width: "60%" }}>
                    Email
                  </TableCell>
                  <TableCell align="center" colSpan={1} sx={{ width: "30%" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userAuthorized?.data.map((user) => (
                  <UserAuthorizedRow key={user.id} users={user} />
                ))}
              </TableBody>

              <TableFooter sx={{ borderTop: "1px solid rgb(224, 224, 224)" }}>
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    {`${currentPageAuthorized} / ${userAuthorized?.metadata.pageCount}`}
                    <IconButton
                      disabled={
                        userAuthorized?.metadata.page === 0 ||
                        currentPageAuthorized === 1
                      }
                      onClick={() =>
                        setCurrentPageAuthorized(currentPageAuthorized - 1)
                      }
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        currentPageAuthorized ===
                        userAuthorized?.metadata.pageCount
                      }
                      onClick={() =>
                        setCurrentPageAuthorized(currentPageAuthorized + 1)
                      }
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </SubcontainerTable>
          </ContainerTable>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContainerTable>
            <SubcontainerTableUnauthorized
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ color: "#213358", fontWeight: 600 }}
                  >
                    Unauthorized users
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={1} sx={{ width: "10%" }}>
                    Id
                  </TableCell>
                  <TableCell align="left" colSpan={1} sx={{ width: "20%" }}>
                    Nombre
                  </TableCell>
                  <TableCell align="left" colSpan={1} sx={{ width: "40%" }}>
                    Email
                  </TableCell>
                  <TableCell align="center" colSpan={1} sx={{ width: "20%" }}>
                    Fecha solicitud
                  </TableCell>
                  <TableCell align="center" colSpan={1} sx={{ width: "5%" }}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersUnauthorized?.data.map((user) => (
                  <UserUnauthorizedRow
                    key={user.userId}
                    usersUnauthorized={user}
                  />
                ))}
              </TableBody>

              <TableFooter sx={{ borderTop: "1px solid rgb(224, 224, 224)" }}>
                <TableRow>
                  <TableCell align="right" colSpan={4}>
                    {`${currentPageUnauthorized} / ${usersUnauthorized?.metadata.pageCount}`}
                    <IconButton
                      disabled={
                        usersUnauthorized?.metadata.page === 0 ||
                        currentPageUnauthorized === 1
                      }
                      onClick={() =>
                        setCurrentPageUnauthorized(currentPageUnauthorized - 1)
                      }
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        currentPageUnauthorized ===
                        usersUnauthorized?.metadata.pageCount
                      }
                      onClick={() =>
                        setCurrentPageUnauthorized(currentPageUnauthorized + 1)
                      }
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </SubcontainerTableUnauthorized>
          </ContainerTable>
        </TabPanel>
      </StyledGrid>
    </AppContainer>
  );
}

import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Box,
  Button,
  Divider,
  Grid,
  Input,
  styled,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import RemoveIcon from "@mui/icons-material/Remove";
import LoupeIcon from "@mui/icons-material/Loupe";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Types
import { CommunityRowProps } from "../types/communities";
import { AddEditCommunityModalFormProps } from "../types/customized";

// Services
import {
  useDeleteCommunity,
  useUpdateCommunityData,
} from "../services/communities";

// Components
import { Link } from "react-router-dom";
import AddEditCommunityModal from "./AddEditCommunityModal";
import AppCancelButton from "../components/AppCancelButton";
import AppSubmitButton from "../components/AppSubmitButton";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});
const StlyedParagraph = styled("p")({
  color: "#762d70",
});
const CommunityRow: FunctionComponent<CommunityRowProps> = ({ community }) => {
  const [pictureName, setPictureName] = useState<string | null>(
    community.photo && community.photo.length > 1
      ? community.photo.substring(62)
      : "Choose and image file"
  );
  const { mutate: deleteCommunity } = useDeleteCommunity();
  const { mutate: updateCommunity } = useUpdateCommunityData();
  const [communityEditing, setCommunityEditing] = useState(false);
  const handleCloseCommunityEditing = () => setCommunityEditing(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<AddEditCommunityModalFormProps>({
    defaultValues: {
      key: "",
      nameEn: "",
      nameEs: "",
      namePt: "",
      descriptionEn: "",
      descriptionEs: "",
      descriptionPt: "",
      photo: community.photo || community.photo || [],
      id: "",
    },
  });

  const handleCommunityEdit = () => {
    setCommunityEditing(true);
  };
  const getEsCommunity = community.languajes.find(
    (language) => language.culture === "es"
  );

  const getEnCommunity = community?.languajes.find(
    (language) => language.culture === "en"
  );

  const getPtCommunity = community?.languajes.find(
    (language) => language.culture === "pt"
  );

  useEffect(() => {
    if (communityEditing) {
      setValue("photo", community.photo);
      setValue("id", community.id);
      setValue("nameEs", `${getEsCommunity?.name}`);
      setValue("descriptionEs", `${getEsCommunity?.description}`);
      setValue("nameEn", `${getEnCommunity?.name}`);
      setValue("descriptionEn", `${getEnCommunity?.description}`);
      setValue("namePt", `${getPtCommunity?.name}`);
      setValue("descriptionPt", `${getPtCommunity?.description}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityEditing]);

  useEffect(() => {
    if (community.photo) {
      const initialName = community.photo.substring(62);
      if (initialName.length > 50) {
        let name = initialName.substring(0, 50);
        name = name + ` (...)`;
        setPictureName(name);
      } else setPictureName(initialName);
    }
  }, [setPictureName, community.photo]);

  return (
    <React.Fragment>
      <StyledRow
        key={community.key}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "30%" }}
        >
          <Box display="flex" alignItems="center">
            <Link to={`/community-detail/${community.id}`}>
              <Tooltip title="View detail" placement="top" arrow>
                <Button
                  color="secondary"
                  variant="text"
                  sx={{ minWidth: "15px", py: "6px", pl: "0px", pr: "6px" }}
                >
                  <LoupeIcon sx={{ fontSize: 15, color: "#213358" }} />
                </Button>
              </Tooltip>
            </Link>
            {getEsCommunity?.name}
          </Box>
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "30%" }}
        >
          {getEnCommunity?.name}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "30%" }}
        >
          {getPtCommunity?.name}
        </TableCell>
        <TableCell align="left" colSpan={3} sx={{ width: "10%" }}>
          <Box display="flex">
            <Tooltip title="Edit" placement="top" arrow>
              <Button onClick={handleCommunityEdit} sx={{ minWidth: "30px" }}>
                <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
              </Button>
            </Tooltip>
            <Tooltip title="Remove" placement="top" arrow>
              <Button
                onClick={() => {
                  deleteCommunity({ id: Number(community.id) });
                }}
                sx={{ minWidth: "30px" }}
              >
                <RemoveIcon sx={{ fontSize: 15, color: "#213358" }} />
              </Button>
            </Tooltip>
          </Box>
        </TableCell>
      </StyledRow>
      {communityEditing && (
        <AddEditCommunityModal
          handleClose={handleCloseCommunityEditing}
          show={communityEditing}
          modalHeader="Edit community"
        >
          <Grid item xs={12} display="flex">
            <Grid
              item
              xs={4}
              textAlign="left"
              sx={{ pl: 0, pt: 0, pb: "1rem", pr: "1rem" }}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  EN
                </Typography>
                <Box sx={{ pb: "1rem" }}>
                  <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                  <Input
                    placeholder="In English"
                    {...register("nameEn", {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  {errors?.nameEn?.type === "required" && (
                    <StlyedParagraph>
                      <ErrorRoundedIcon fontSize="small" /> This field is
                      required
                    </StlyedParagraph>
                  )}
                  {errors?.nameEn?.type === "maxLength" && (
                    <StlyedParagraph>
                      <ErrorRoundedIcon fontSize="small" /> Max 150 characters
                      allowed.
                    </StlyedParagraph>
                  )}
                </Box>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                  Description
                </Box>
                <Input
                  placeholder="In English"
                  {...register("descriptionEn", {
                    required: true,
                    maxLength: 300,
                  })}
                />
                {errors?.descriptionEn?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.descriptionEn?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 300 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              textAlign="left"
              sx={{ pl: 0, pt: 0, pb: "1rem", pr: "1rem" }}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  ES
                </Typography>
                <Box sx={{ pb: "1rem" }}>
                  <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                  <Input
                    placeholder="In Spanish"
                    {...register("nameEs", {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  {errors?.nameEs?.type === "required" && (
                    <StlyedParagraph>
                      <ErrorRoundedIcon fontSize="small" /> This field is
                      required
                    </StlyedParagraph>
                  )}
                  {errors?.nameEs?.type === "maxLength" && (
                    <StlyedParagraph>
                      <ErrorRoundedIcon fontSize="small" /> Max 150 characters
                      allowed.
                    </StlyedParagraph>
                  )}
                </Box>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                  Description
                </Box>
                <Input
                  placeholder="In Spanish"
                  {...register("descriptionEs", {
                    required: true,
                    maxLength: 300,
                  })}
                />
                {errors?.descriptionEs?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.descriptionEs?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 300 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={4}
              textAlign="left"
              sx={{ pl: 0, pt: 0, pb: "1rem" }}
            >
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  PT
                </Typography>
                <Box sx={{ pb: "1rem" }}>
                  <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                  <Input
                    placeholder="In Portuguese"
                    {...register("namePt", {
                      required: true,
                      maxLength: 150,
                    })}
                  />
                  {errors?.namePt?.type === "required" && (
                    <StlyedParagraph>
                      <ErrorRoundedIcon fontSize="small" /> This field is
                      required
                    </StlyedParagraph>
                  )}
                  {errors?.namePt?.type === "maxLength" && (
                    <StlyedParagraph>
                      <ErrorRoundedIcon fontSize="small" /> Max 150 characters
                      allowed.
                    </StlyedParagraph>
                  )}
                </Box>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                  Description
                </Box>
                <Input
                  placeholder="In Portuguese"
                  {...register("descriptionPt", {
                    required: true,
                    maxLength: 300,
                  })}
                />
                {errors?.descriptionPt?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.descriptionPt?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 300 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              component="span"
              sx={{
                width: "100%",
                cursor: "pointer",
                fontSize: ".9rem",
                padding: ".2rem",
                backgroundColor: "#e9ecef",
                color: "#213358",
                borderRadius: "10px",
                marginBottom: "1rem",
              }}
            >
              <label>
                <Box>{`${pictureName}`}</Box>
                <Input
                  sx={{ display: "none" }}
                  type="file"
                  {...register("photo", {
                    onChange: (e) => {
                      if (e.target.files) {
                        if (e.target.files[0].name.length > 50) {
                          let name = e.target.files[0].name.substring(0, 50);
                          name = name + ` (...)`;
                          setPictureName(name);
                        } else setPictureName(e.target.files[0].name);
                      } else setPictureName("Choose an image file");
                    },
                  })}
                  inputProps={{ accept: "image/*" }}
                />
              </label>
            </Button>
          </Grid>
          <Divider sx={{ width: "100%" }} />
          <Grid item xs={12} textAlign="end">
            <AppCancelButton onClick={handleCloseCommunityEditing}>
              Cancel
            </AppCancelButton>
            <AppSubmitButton
              submitbtnwidth="20%"
              onClick={handleSubmit((formValues) => {
                console.log("edit", formValues.photo[0]);
                const formDataEs = new FormData();
                formDataEs.append("id", community.id);
                formDataEs.append("name", formValues.nameEs);
                formDataEs.append("description", formValues.descriptionEs);
                formDataEs.append("culture", "es");
                if (formValues.photo.length === 1) {
                  formDataEs.append("files", formValues.photo[0]);
                } else {
                  formDataEs.append("files", community.photo);
                }
                const formDataEn = new FormData();
                formDataEn.append("id", community.id);
                formDataEn.append("name", formValues.nameEn);
                formDataEn.append("description", formValues.descriptionEn);
                formDataEn.append("culture", "en");
                if (formValues.photo.length === 1) {
                  formDataEn.append("files", formValues.photo[0]);
                } else formDataEn.append("files", community.photo);
                const formDataPt = new FormData();
                formDataPt.append("id", community.id);
                formDataPt.append("name", formValues.namePt);
                formDataPt.append("description", formValues.descriptionPt);
                formDataPt.append("culture", "pt");
                if (formValues.photo.length === 1) {
                  formDataPt.append("files", formValues.photo[0]);
                } else formDataEn.append("files", community.photo);
                updateCommunity(formDataEs);
                updateCommunity(formDataEn);
                updateCommunity(formDataPt);
                reset();
                handleCloseCommunityEditing();
              })}
            >
              Submit
            </AppSubmitButton>
          </Grid>
        </AddEditCommunityModal>
      )}
    </React.Fragment>
  );
};

export default CommunityRow;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Components
import AppSubmitButton from "../components/AppSubmitButton";
import AddEditModal from "../shared/AddEditModal";
import {
  Box,
  Button,
  Input,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

// Icons
import EditSharpIcon from "@mui/icons-material/EditSharp";
import RemoveIcon from "@mui/icons-material/Remove";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Services

import { UserAuthorizedRowProps } from "../types/users";
import {
  useRemoveUserAuthorizated,
  useUpdateUserPreauthorized,
} from "../services/userAuthorize";

// Styles
const StyledParagraph = styled(Box)({
  color: "red",
  display: "flex",
  alignItems: "center",
  marginTop: "10px",
});

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const UserAuthorizedRow = ({ users }: UserAuthorizedRowProps) => {
  const [maxCharacter, setMaxCharacter] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [disableBtn, setDisableBtn] = React.useState(true);
  const [emailPreauthorizedEditing, setEmailPreauthorizedEditing] =
    useState(false);
  const { mutate: deleteUserAuthorize } = useRemoveUserAuthorizated();
  const { mutate: updateUserPreauthorized } = useUpdateUserPreauthorized();

  useEffect(() => {
    if (emailPreauthorizedEditing) {
      setEmail(users.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailPreauthorizedEditing]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const regex =
      /^\s*[a-zA-Z0-9._-]+[@][a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,7}\s*$/;
    const isValid = regex.test(inputValue);

    if (inputValue.length >= 100) {
      setMaxCharacter(true);
      setErrorText("Character limit exceeded");
    } else {
      setMaxCharacter(false);
      setEmail(inputValue);
      setErrorText("");
      if (isValid) {
        setDisableBtn(false);
      } else {
        setDisableBtn(true);
      }
    }
  };

  const handleUpdateUserPreauthorized = () => {
    updateUserPreauthorized({ id: users.id, email: email });
    setEmailPreauthorizedEditing(false);
  };

  return (
    <>
      <StyledRow
        key={users.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          sx={{ width: "10%", wordWrap: "anywhere" }}
          align="center"
          scope="row"
        >
          {users.id}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "50%" }}
        >
          {users.email}
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "40%" }}
        >
          <Tooltip title="Edit" placement="top" arrow>
            <Button
              onClick={() => setEmailPreauthorizedEditing(true)}
              sx={{ minWidth: "30px" }}
            >
              <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Remove" placement="top" arrow>
            <Button
              onClick={() => {
                deleteUserAuthorize(users.id);
              }}
              sx={{ minWidth: "30px" }}
            >
              <RemoveIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
        </TableCell>
      </StyledRow>
      <AddEditModal
        modalHeader="Edit email"
        modalInputLabel=""
        handleClose={() => setEmailPreauthorizedEditing(false)}
        show={emailPreauthorizedEditing}
      >
        <Input
          value={email}
          onChange={(e: any) => handleEmailChange(e)}
          type="email"
          error={maxCharacter}
        />
        {maxCharacter && (
          <StyledParagraph>
            <ErrorRoundedIcon fontSize="small" /> {errorText}
          </StyledParagraph>
        )}
        <AppSubmitButton
          disable={disableBtn}
          onClick={handleUpdateUserPreauthorized}
        >
          Submit
        </AppSubmitButton>
      </AddEditModal>
    </>
  );
};

export default UserAuthorizedRow;

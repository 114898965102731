import { Box, styled } from "@mui/material";
import React, { FunctionComponent } from "react";
import { AppBoxProps } from "../types/customized";

const StyledAppBox = styled(Box)((props: AppBoxProps) => ({
  backgroundColor: props.bgColor || "white",
  width: props.width || "20%",
  padding: props.padding || "2rem",
  borderRadius: props.borderRadius || "10px",
}));

const AppBox: FunctionComponent<AppBoxProps> = ({
  children,
  bgColor,
  width,
  padding,
  borderRadius,
}) => {
  return (
    <StyledAppBox
      bgColor={bgColor}
      width={width}
      padding={padding}
      borderRadius={borderRadius}
    >
      {children}
    </StyledAppBox>
  );
};

export default AppBox;

import React, { useState } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  IconButton,
  Input,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AddIcon from "@mui/icons-material/Add";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Components
import AppButton from "../components/AppButton";
import AppCard from "../components/AppCard";
import AdminsRow from "../shared/AdminsRow";
import AddEditModal from "../shared/AddEditModal";
import AppSubmitButton from "../components/AppSubmitButton";
import AppContainer from "../components/AppContainer";

// Services
import { useAddNewAdminData, useAdmins } from "../services/admins";
import { useCurrentAdmin } from "../services/login";
import { useNavigate } from "react-router-dom";
import Loading from "../shared/Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const AdminsConfiguration = () => {
  const getCurrentAdmin = window.localStorage.getItem("currentAdminId");
  const { data: getLoggedAdmin } = useCurrentAdmin(getCurrentAdmin || "");
  const [openNewAdminModal, setOpenNewAdminModal] = useState(false);
  const handleOpenNewAdminModal = () => setOpenNewAdminModal(true);
  const handleCloseNewAdminModal = () => setOpenNewAdminModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: admins, isLoading } = useAdmins(currentPage);
  const { mutate: addAdmin, isLoading: isAddingAdmin } =
    useAddNewAdminData(currentPage);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
    },
  });

  const handleCloseModal = () => {
    reset();
    handleCloseNewAdminModal();
  };

  if (isLoading || isAddingAdmin) {
    return <Loading showBack={true} />;
  }

  return (
    <>
      <AppContainer>
        <AppCard appcardwidth="60%">
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={12}
                    sx={{ color: "#213358", fontWeight: 600 }}
                  >
                    Admins configuration
                    <AppButton
                      tooltiptitle="Create admin"
                      onClick={handleOpenNewAdminModal}
                    >
                      <AddIcon sx={{ color: "#213358" }} />
                    </AppButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={3} sx={{ width: "10%" }}>
                    Id
                  </TableCell>
                  <TableCell align="left" colSpan={3} sx={{ width: "40%" }}>
                    Full name
                  </TableCell>
                  <TableCell align="left" colSpan={3} sx={{ width: "40%" }}>
                    Email
                  </TableCell>
                  <TableCell align="left" colSpan={3} sx={{ width: "10%" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins?.data.map((admin) => (
                  <AdminsRow
                    key={admin.id}
                    admin={admin}
                    currentPage={currentPage}
                  />
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell align="right" colSpan={18}>
                    {`${currentPage} / ${Math.ceil((admins?.count ?? 0) / 10)}`}
                    <IconButton
                      disabled={admins?.count === 0 || currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        admins?.count === 0 ||
                        currentPage === Math.ceil((admins?.count ?? 0) / 10)
                      }
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AppCard>
        <AddEditModal
          handleClose={() => handleCloseModal()}
          show={openNewAdminModal}
          modalHeader="Create admin"
          modalInputLabel="Name"
        >
          <>
            <Input
              placeholder="Full Name"
              {...register("fullName", {
                required: true,
              })}
            />
            {errors?.fullName?.type === "required" && (
              <StlyedParagraph>
                <ErrorRoundedIcon fontSize="small" /> This field is required
              </StlyedParagraph>
            )}
            <Input
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern:
                  /^\s*[a-zA-Z0-9._-]+[@][a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,7}\s*$/,
              })}
            />
            {errors?.email?.type === "required" && (
              <StlyedParagraph>
                <ErrorRoundedIcon fontSize="small" /> This field is required
              </StlyedParagraph>
            )}
            {errors?.email?.type === "pattern" && (
              <StlyedParagraph>
                <ErrorRoundedIcon fontSize="small" /> Email is not valid
              </StlyedParagraph>
            )}
            <AppSubmitButton
              onClick={handleSubmit((formValues) => {
                addAdmin({
                  fullName: formValues.fullName,
                  email: formValues.email,
                  levelAdmin: 2,
                });
                reset();
                handleCloseNewAdminModal();
              })}
            >
              Submit
            </AppSubmitButton>
          </>
        </AddEditModal>
      </AppContainer>
    </>
  );
};

export default AdminsConfiguration;

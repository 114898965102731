import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./axios";

// Types
import {
  GetUserDetailResponse,
  GetUsersResponse,
  UserStatus,
} from "../types/users";

export const useUsers = (props?: {
  page?: number;
  userName?: string;
  email?: string;
  limit?: number;
}) => {
  return useQuery<GetUsersResponse, Error>(
    ["requestUsers", props?.page, props?.userName, props?.email],
    async () => {
      const res = await http.get<"requestUsers", { data: GetUsersResponse }>(
        "user/get-all-users",
        {
          params: {
            page: props?.page || 1,
            limit: props?.limit || 10,
            name: props?.userName,
            email: props?.email,
          },
        }
      );
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

export const useUserDetail = (userId?: string | number) => {
  return useQuery<GetUserDetailResponse, Error>(
    ["requestUserDetail", userId],
    async () => {
      const res = await http.get<
        "requestUserDetail",
        { data: GetUserDetailResponse }
      >("user/get-by-id/en", { params: { id: userId } });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: 30000,
      retry: false,
    }
  );
};

const blockUnblockUser = (userStatus: UserStatus) => {
  return http.post(`user/blocked-un-blocked-user`, userStatus);
};

export const useBlockUnblockUserData = (userId?: string | number) => {
  const queryClient = useQueryClient();
  return useMutation(blockUnblockUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestUserDetail", userId]);
    },
  });
};

import {
  useMutation,
  useQueryClient,
  useQuery,
  UseQueryOptions,
} from "react-query";
import http from "./axios";

// Types
import {
  GetPermissionsPerRoleResponse,
  GetRolesPermissionsResponse,
  Permission,
} from "../types/permissions";

export const useRolePermissions = (
  options: UseQueryOptions<Array<GetRolesPermissionsResponse>, Error> = {}
) => {
  return useQuery<Array<GetRolesPermissionsResponse>, Error>(
    ["requestRolePermissions"],
    async () => {
      const res = await http.get<
        "requestRolePermissions",
        { data: Array<GetRolesPermissionsResponse> }
      >("permission/get-all-permission");
      return res.data;
    },
    options
  );
};

export const usePermissionsPerRole = (RoleId: number) => {
  return useQuery<GetPermissionsPerRoleResponse, Error>(
    ["requestPermissionsPerRole", RoleId],
    async () => {
      const res = await http.get<
        "requestPermissionsPerRole",
        { data: GetPermissionsPerRoleResponse }
      >("roles/get-permission-by-role", { params: { RoleId: RoleId } });
      return res.data;
    },
    {
      refetchInterval: 30000,
    }
  );
};

const addPermission = (permission: Permission) => {
  return http.post("roles/add-permission-role", permission);
};

export const useAddPermissionData = () => {
  const queryClient = useQueryClient();
  return useMutation(addPermission, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestPermissionsPerRole");
    },
  });
};

const removePermissionPerRole = (permission: Permission) => {
  return http.delete("roles/delete-permission-role", { data: permission });
};

export const useDeletePermissionPerRole = () => {
  const queryClient = useQueryClient();
  return useMutation(removePermissionPerRole, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestPermissionsPerRole");
    },
  });
};

import React, { FunctionComponent } from "react";

// Styles
import { Button, styled, Tooltip } from "@mui/material";

// Types
import { AppButtonProps } from "../types/customized";

const StyledAppButton = styled(Button)((props: AppButtonProps) => ({
  color: props.appbuttoncolor || "#762d70",
  margin: props.appbuttonmargin || 5,
  flex: props.appbuttonflex || 1,
  padding: props.appbuttonpadding || 0,
  minWidth: props.appbuttonminwidth || 0,
}));
const AppButton: FunctionComponent<AppButtonProps> = ({
  children,
  appbuttoncolor,
  appbuttonmargin,
  appbuttonpadding,
  appbuttonflex,
  appbuttonminwidth,
  onClick,
  tooltiptitle,
}) => {
  return (
    <>
      <Tooltip title={tooltiptitle} placement="top" arrow>
        <StyledAppButton
          appbuttoncolor={appbuttoncolor}
          appbuttonflex={appbuttonflex}
          appbuttonmargin={appbuttonmargin}
          appbuttonminwidth={appbuttonminwidth}
          appbuttonpadding={appbuttonpadding}
          onClick={onClick}
          tooltiptitle={tooltiptitle}
        >
          {children}
        </StyledAppButton>
      </Tooltip>
    </>
  );
};

export default AppButton;

import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";

// Styles
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// Components
import AppModal2 from "../components/AppModal2";
import AppBox from "../components/AppBox";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import AppSubmitButton from "../components/AppSubmitButton";

// Types
import { HashtagsFilterModalProps } from "../types/hashtags";
import AppCancelButton from "../components/AppCancelButton";

const HashtagsFilterModal: FunctionComponent<HashtagsFilterModalProps> = ({
  open,
  onClose,
  setStatus,
  setHashtag,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      blocked: 0,
      name: "",
    },
  });

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  return (
    <AppModal2 open={open} onClose={() => handleCloseModal()}>
      <AppBox width="25%">
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <Typography flex={8} variant="h5" component="div">
              Filters{" "}
            </Typography>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <IconButton onClick={() => handleCloseModal()}>
              <CloseRoundedIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ mb: "2%" }}>
            <Grid item xs={4} alignSelf="center">
              <FormLabel>Hashtag name</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Input {...register("name")} />
            </Grid>
          </Grid>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Status
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Active"
                {...register("blocked")}
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Blocked"
                {...register("blocked")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} display="flex" justifyContent={"flex-end"}>
          <AppCancelButton onClick={() => handleCloseModal()}>
            Cancel
          </AppCancelButton>
          <AppSubmitButton
            submitbtnwidth="20%"
            onClick={handleSubmit((data) => {
              setStatus(data.blocked);
              reset();
              onClose();
              setHashtag(data.name);
            })}
          >
            Filter
          </AppSubmitButton>
        </Grid>
      </AppBox>
    </AppModal2>
  );
};

export default HashtagsFilterModal;

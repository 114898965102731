import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Button,
  Input,
  styled,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Types
import { AdminsRowProps } from "../types/admins";

// Components
import AddEditModal from "./AddEditModal";
import AppSubmitButton from "../components/AppSubmitButton";
import { useUpdateAdminData } from "../services/admins";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});
const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const AdminsRow: FunctionComponent<AdminsRowProps> = ({
  admin,
  currentPage,
}) => {
  const [openAdminEditing, setOpenAdminEditing] = useState(false);
  const handleCloseAdminEditing = () => setOpenAdminEditing(false);
  const handleAdminEdit = () => {
    setOpenAdminEditing(true);
  };
  const { mutate: updateAdmin } = useUpdateAdminData(currentPage);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
    },
  });

  useEffect(() => {
    setValue("fullName", `${admin?.fullName}`);
    setValue("email", `${admin?.email}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StyledRow>
        <TableCell
          align="center"
          colSpan={3}
          sx={{ width: "20%", wordWrap: "anywhere" }}
        >
          {admin.id}
        </TableCell>
        <TableCell
          align="left"
          colSpan={3}
          sx={{ width: "40%", wordWrap: "anywhere" }}
        >
          {admin.fullName}
        </TableCell>
        <TableCell
          align="left"
          colSpan={3}
          sx={{ width: "20%", wordWrap: "anywhere" }}
        >
          {admin.email}
        </TableCell>
        <TableCell align="left" colSpan={3} sx={{ width: "10%" }}>
          <Tooltip title="Edit" placement="top" arrow>
            <Button onClick={handleAdminEdit}>
              <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
        </TableCell>
      </StyledRow>
      {openAdminEditing && (
        <AddEditModal
          modalHeader="Edit admin"
          modalInputLabel="Admin"
          handleClose={handleCloseAdminEditing}
          show={openAdminEditing}
          currentData={admin}
        >
          <>
            <Input
              placeholder="Full Name"
              {...register("fullName", {
                required: true,
              })}
            />
            {errors?.fullName?.type === "required" && (
              <StlyedParagraph>
                <ErrorRoundedIcon fontSize="small" /> This field is required
              </StlyedParagraph>
            )}
            <Input
              placeholder="Email"
              {...register("email", {
                required: true,
                pattern:
                  /^\s*[a-zA-Z0-9._-]+[@][a-zA-Z0-9._-]+[.][a-zA-Z0-9]{2,7}\s*$/,
              })}
            />
            {errors?.email?.type === "required" && (
              <StlyedParagraph>
                <ErrorRoundedIcon fontSize="small" /> This field is required
              </StlyedParagraph>
            )}
            {errors?.email?.type === "pattern" && (
              <StlyedParagraph>
                <ErrorRoundedIcon fontSize="small" /> Email is not valid
              </StlyedParagraph>
            )}
            <AppSubmitButton
              onClick={handleSubmit((data) => {
                updateAdmin({
                  fullName: data.fullName,
                  email: data.email,
                  levelAdmin: 2,
                  id: admin.id,
                });
                reset();
                handleCloseAdminEditing();
              })}
            >
              Submit
            </AppSubmitButton>
          </>
        </AddEditModal>
      )}
    </>
  );
};

export default AdminsRow;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Button,
  TableRow,
  TableCell,
  Tooltip,
  Input,
  styled,
} from "@mui/material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import RemoveIcon from "@mui/icons-material/Remove";

// Components
import AddEditModal from "../shared/AddEditModal";

// Services
import {
  useDeleteSpecialty,
  useUpdateSpecialtyData,
} from "../services/specialties";

// Types
import { SpecialtyFormProps, SpecialtyRowProps } from "../types/specialties";
import AppSubmitButton from "./AppSubmitButton";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const SpecialtyRow = ({ specialty, currentPage }: SpecialtyRowProps) => {
  const [specialtyEditing, setSpecialtyIsEditing] = useState(false);
  const handleCloseSpecialtyEditing = () => setSpecialtyIsEditing(false);
  const handleSpecialtyEdit = () => {
    setSpecialtyIsEditing(true);
  };
  const { mutate: deleteSpecialty } = useDeleteSpecialty(currentPage);
  const { mutate: updateSpecialty } = useUpdateSpecialtyData();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<SpecialtyFormProps>({
    defaultValues: {
      id: null,
      es: "",
      en: "",
      pt: "",
    },
  });

  const getEsLanguage = specialty.languajes.find(
    (language) => language.culture === "es"
  );

  const getEnLanguage = specialty.languajes.find(
    (language) => language.culture === "en"
  );

  const getPtLanguage = specialty.languajes.find(
    (language) => language.culture === "pt"
  );

  useEffect(() => {
    if (specialtyEditing) {
      setValue("id", specialty?.id);
      setValue("es", `${getEsLanguage?.description}`);
      setValue("en", `${getEnLanguage?.description}`);
      setValue("pt", `${getPtLanguage?.description}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialtyEditing]);

  return (
    <>
      <StyledRow
        key={specialty.key}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          align="left"
          scope="row"
          key={getEsLanguage?.id}
          sx={{ wordWrap: "anywhere", width: "28%" }}
        >
          {getEsLanguage?.description}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          key={getEnLanguage?.id}
          sx={{ wordWrap: "anywhere", width: "28%" }}
        >
          {getEnLanguage?.description}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          key={getPtLanguage?.id}
          sx={{ wordWrap: "anywhere", width: "28%" }}
        >
          {getPtLanguage?.description}
        </TableCell>
        <TableCell align="left" colSpan={3} sx={{ width: "16%" }}>
          <Tooltip title="Edit" placement="top" arrow>
            <Button onClick={handleSpecialtyEdit} sx={{ minWidth: "30px" }}>
              <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
          <Tooltip title="Remove" placement="top" arrow>
            <Button
              onClick={() => {
                deleteSpecialty({ id: specialty.id });
              }}
              sx={{ minWidth: "30px" }}
            >
              <RemoveIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
        </TableCell>
      </StyledRow>
      <AddEditModal
        modalHeader="Edit specialty"
        modalInputLabel="Specialty"
        handleClose={handleCloseSpecialtyEditing}
        show={specialtyEditing}
      >
        <>
          <Input
            {...register("es", {
              required: true,
            })}
          />
          {errors?.es?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            {...register("en", {
              required: true,
            })}
          />
          {errors?.en?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            {...register("pt", {
              required: true,
            })}
          />
          {errors?.pt?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <AppSubmitButton
            onClick={handleSubmit((data) => {
              updateSpecialty({
                id: specialty.id,
                description: data.es,
                culture: "es",
              });
              updateSpecialty({
                id: specialty.id,
                description: data.en,
                culture: "en",
              });
              updateSpecialty({
                id: specialty.id,
                description: data.pt,
                culture: "pt",
              });
              reset();
              setSpecialtyIsEditing(false);
            })}
          >
            Submit
          </AppSubmitButton>
        </>
      </AddEditModal>
    </>
  );
};
export default SpecialtyRow;

import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

// Styled
import {
  IconButton,
  Table,
  TableFooter,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

// Components
import AppTableRow from "../components/AppTableRow";
import FilterModal from "../components/FilterModal";
import AppButton from "../components/AppButton";
import AppCard from "../components/AppCard";
import AppContainer from "../components/AppContainer";

// Services
import { usePosts } from "../services/posts";
import Loading from "../shared/Loading";

const PostsConfiguration = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [postId, setPostId] = useState<number>();
  const [postUserName, setPostUserName] = useState<string>();
  const [postDate, setPostDate] = useState<number>();
  const [postOrder, setPostOrder] = useState(1);
  const [postStatus, setPostStatus] = useState<boolean>();

  const { data: posts, isLoading } = usePosts({
    page: currentPage,
    postId: postId,
    userName: postUserName,
    datePublish: postDate,
    OrderByReportDesc: postOrder,
    isBlocked: postStatus,
  });
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const handleOpenFilterModal = () => setOpenFilterModal(true);
  const handleCloseFilterModal = () => setOpenFilterModal(false);

  useEffect(() => {
    if (postId || postUserName || postDate || postOrder || postStatus) {
      setCurrentPage(1);
    }
  }, [postId, postUserName, postDate, postOrder, postStatus]);

  if (isLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <>
      <AppContainer containerPaddingTop="2%">
        <AppCard appcardwidth="70%">
          <TableContainer>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={18}
                    sx={{ fontWeight: 600, color: "#213358" }}
                  >
                    Posts configuration
                    <AppButton
                      tooltiptitle="Filters"
                      onClick={handleOpenFilterModal}
                    >
                      <FilterAltOutlinedIcon sx={{ color: "#213358" }} />
                    </AppButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ width: "10%" }}>
                    Id
                  </TableCell>
                  <TableCell align="left" colSpan={3} sx={{ width: "40%" }}>
                    User Name
                  </TableCell>
                  <TableCell align="left" colSpan={3} sx={{ width: "15%" }}>
                    Date
                  </TableCell>
                  <TableCell align="center" colSpan={3} sx={{ width: "15%" }}>
                    Reports #
                  </TableCell>
                  <TableCell align="center" colSpan={3} sx={{ width: "20%" }}>
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {posts?.data.map((post) => (
                  <AppTableRow key={post.id} post={post} />
                ))}
              </TableBody>
              <TableFooter sx={{ width: "100%" }}>
                <TableRow>
                  <TableCell align="right" colSpan={18}>
                    {`${currentPage} / ${Math.ceil((posts?.count ?? 0) / 10)}`}
                    <IconButton
                      disabled={posts?.count === 0 || currentPage === 1}
                      onClick={() => setCurrentPage(currentPage - 1)}
                    >
                      <ArrowBackIosRoundedIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      disabled={
                        posts?.count === 0 ||
                        currentPage === Math.ceil((posts?.count ?? 0) / 10)
                      }
                      onClick={() => setCurrentPage(currentPage + 1)}
                    >
                      <ArrowForwardIosRoundedIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </AppCard>
        <Outlet />
      </AppContainer>
      <FilterModal
        open={openFilterModal}
        handleClose={handleCloseFilterModal}
        setPostId={setPostId}
        setPostUserName={setPostUserName}
        setPostDate={setPostDate}
        setPostOrder={setPostOrder}
        setPostStatus={setPostStatus}
      />
    </>
  );
};

export default PostsConfiguration;

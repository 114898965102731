import { useMutation, useQueryClient, useQuery } from "react-query";
import http from "./axios";
import Swal from "sweetalert2";

// Types
import {
  GetRolesByCultureResponse,
  GetRolesResponse,
  MathUserToRole,
} from "../types/roles";

// Get
export const useRoles = (page: number) => {
  return useQuery<GetRolesResponse, Error>(
    ["requestRoles", page],
    async () => {
      const res = await http.get<"requestRoles", { data: GetRolesResponse }>(
        "roles/get-all-roles-with-languaje",
        { params: { page: page, limit: 10 } }
      );
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
};

export const useRolesByCulture = (
  page: number,
  limit = 10,
  culture: string
) => {
  return useQuery<GetRolesByCultureResponse, Error>(
    ["requestRolesByCulture", page],
    async () => {
      const res = await http.get<
        "requestRolesByCulture",
        { data: GetRolesByCultureResponse }
      >(`roles/get-all-roles/${culture}`, {
        params: { page: page, limit: limit },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
};

export const matchUserToRole = async (userId: number, roleId: number) => {
  return await http.post<"matchUserToRole", { data: MathUserToRole }>(
    `roles/role-user`,
    {
      rolId: roleId,
      userId: userId,
    }
  );
};

// Post add
const addNewRole = (role: { description: string; culture: string }[]) => {
  return http.post(`roles/add/`, role);
};

export const useAddNewRoleData = () => {
  const queryClient = useQueryClient();
  return useMutation(addNewRole, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestRoles");
    },
  });
};

// Post update
const updateRole = (role: {
  id: number | string;
  description: string;
  culture: string;
}) => {
  return http.post(`roles/edit/${role.culture}`, role);
};

export const useUpdateRoleData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateRole, {
    onSuccess: () => {
      queryClient.invalidateQueries("requestRoles");
    },
  });
};

// Delete
const removeRole = (role: { id: number }) => {
  return http.delete("roles/delete", { data: role });
};

export const useDeleteRole = () => {
  const queryClient = useQueryClient();
  return useMutation(removeRole, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("requestRoles");
      if (!response.data.isSuccess) {
        Swal.fire({
          title: "Error",
          text: "The role is assigned to a user and cannot be deleted.",
          icon: "warning",
          confirmButtonColor: "#3db1c8",
          confirmButtonText: "Ok",
          iconColor: "#762d70",
        });
      }
    },
  });
};

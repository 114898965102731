import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

// Styles
import { styled, Box, Grid, Button } from "@mui/material";

// Types
import { AppDetailProps } from "../types/customized";

const StyledPostDetailUserName = styled(Box)({
  textAlign: "left",
  fontSize: "1rem",
  color: "#3db1c8",
  fontWeight: "500",
});

const StyledPostDetailIdBox = styled(Box)({
  fontSize: "1rem",
  textAlign: "left",
});

const AppDetail: FunctionComponent<AppDetailProps> = ({ posts }) => {
  let translatedContent: string | undefined = posts?.content;
  const translateHashtags = () => {
    if (posts?.hashtags) {
      posts?.hashtags.forEach((postComment) => {
        translatedContent = translatedContent?.replace(
          postComment.id.toString(),
          postComment.value.toString()
        );
      });
    }
    if (posts?.mentions) {
      posts?.mentions.forEach((postMention) => {
        translatedContent = translatedContent?.replace(
          postMention.id.toString(),
          postMention.value.toString()
        );
      });
    }
  };
  translateHashtags();
  return (
    <Box
      sx={{
        boxShadow:
          "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
        borderRadius: "20px",
        marginBottom: "5%",
      }}
    >
      <Grid display="flex" sx={{ paddingLeft: 0 }}>
        <Grid container display="flex" flexDirection="row" padding="1.5rem">
          <Grid item xs={10} alignItems="left">
            <StyledPostDetailUserName>
              {posts?.userName}
            </StyledPostDetailUserName>
          </Grid>
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <StyledPostDetailIdBox>{posts?.id}</StyledPostDetailIdBox>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="left">
            <Box sx={{ fontSize: ".8rem", color: "#8f8f8f" }}>
              {posts?.postDate.substring(0, 10)}
            </Box>
          </Grid>
          <Grid item xs={12} display="flex">
            <Box
              sx={{
                maxHeight: "20vh",
                overflowY: "auto",
                textAlign: "initial",
              }}
            >
              {translatedContent}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Link to={`/post-detail/${posts?.id}`}>
              <Button color="primary" variant="text">
                View post detail
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AppDetail;

import React from "react";
import AppRouter from "./router";
import theme from "./theme";

// Components
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";

const queryClient = new QueryClient();
function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;

import React, { FunctionComponent } from "react";

// Styles
import {
  Box,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  TableCell,
  TableRow,
} from "@mui/material";

// Types
import { HashtagRowProps } from "../types/hashtags";

// Services
import { useBlockUnblockHashtagData } from "../services/hashtags";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const HashtagRow: FunctionComponent<HashtagRowProps> = ({
  hashtag,
  currentPage,
}) => {
  const { mutate: handleBlockStatus } = useBlockUnblockHashtagData(currentPage);
  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleBlockStatus({
      id: hashtag.id,
      blocked: e.target.checked,
    });
  };

  return (
    <>
      <StyledRow
        key={hashtag.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          sx={{ wordWrap: "anywhere", width: "70%" }}
          align="left"
          scope="row"
        >
          {hashtag.description}
        </TableCell>
        <TableCell align="left" scope="row" sx={{ width: "30%" }}>
          <FormControlLabel
            sx={{ marginRight: 0 }}
            control={
              <Switch
                color="primary"
                size={"small"}
                checked={hashtag.statusId === 2 ? true : false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleStatus(e)
                }
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={`${hashtag.statusId === 2 ? "Blocked" : "Active"}`}
          />
        </TableCell>
      </StyledRow>
    </>
  );
};

export default HashtagRow;

import React, { useState } from "react";

// Components
import { Button, styled, TableCell, TableRow, Tooltip } from "@mui/material";

// Icons
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FlagIcon from "@mui/icons-material/Flag";
// Services

import { UsersUnauthorizedRowProps } from "../types/users";
import AppModalUnauthorized from "../components/AppModalUnauthorized";
import { useGetUserUnauthorizedData } from "../services/userAuthorize";

// Styles
const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const UserUnauthorizedRow = ({
  usersUnauthorized,
}: UsersUnauthorizedRowProps) => {
  const applicationDate = usersUnauthorized.requestDate.split("T")[0];
  const [userIsReviewing, setUserIsReviewing] = useState(false);
  const { mutate: getUserAuthorized, data } = useGetUserUnauthorizedData();

  const handleGetDataUserUnauthorized = () => {
    getUserAuthorized(usersUnauthorized.userId);
    setUserIsReviewing(true);
  };

  return (
    <>
      <StyledRow
        key={usersUnauthorized.userId}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          sx={{ width: "10%", wordWrap: "anywhere" }}
          align="left"
          scope="row"
        >
          {usersUnauthorized.userId}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{
            wordWrap: "anywhere",
            width: "20%",
            textTransform: "capitalize",
          }}
        >
          {usersUnauthorized.fullName}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "40%" }}
        >
          {usersUnauthorized.email}
        </TableCell>
        <TableCell
          align="center"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "20%" }}
        >
          {applicationDate}
        </TableCell>
        <TableCell
          align="left"
          scope="row"
          sx={{ wordWrap: "anywhere", width: "5%" }}
        >
          <Tooltip title="Edit" placement="top" arrow>
            <Button
              onClick={handleGetDataUserUnauthorized}
              sx={{ minWidth: "10px" }}
            >
              <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
          {!usersUnauthorized.isRequestReviewed && (
            <FlagIcon sx={{ fontSize: 15, color: "#a31717" }}></FlagIcon>
          )}
        </TableCell>
      </StyledRow>
      {data && (
        <AppModalUnauthorized
          handleClose={() => {
            setUserIsReviewing(false);
          }}
          show={userIsReviewing}
          dataUser={data.data.data}
        />
      )}
    </>
  );
};

export default UserUnauthorizedRow;

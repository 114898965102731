import React, { FunctionComponent } from "react";

// Styled
import { Button, styled } from "@mui/material";

// Types
import { AppCancelButtonProps } from "../types/customized";

const StyledCancelButton = styled(Button)((props: AppCancelButtonProps) => ({
  marginTop: props.cancelbtnmargintop || "4%",
  background: props.cancelButtonBackground || "white",
  borderRadius: props.cancelButtonBorderRadius || "15px",
  width: props.cancelbtnwidth || "20%",
  color: props.cancelButtonColor || "#757171",
  marginRight: props.cancelButtonMarginR || "1rem",
  "&:hover": {
    backgroundColor: "transparent",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
}));
const AppCancelButton: FunctionComponent<AppCancelButtonProps> = ({
  cancelbtnwidth,
  cancelbtnmargintop,
  children,
  onClick,
}) => {
  return (
    <StyledCancelButton
      onClick={onClick}
      variant="contained"
      type="submit"
      cancelbtnmargintop={cancelbtnmargintop}
      cancelbtnwidth={cancelbtnwidth}
    >
      {children}
    </StyledCancelButton>
  );
};

export default AppCancelButton;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminContext } from "../auth/adminContext";

// Styles
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ArticleIcon from "@mui/icons-material/Article";
import { DoctorIcon } from "../icons/doctorIcon";
import TagIcon from "@mui/icons-material/Tag";
import PeopleIcon from "@mui/icons-material/People";
import CaselinkIcon from "../assets/images/link.svg";
import GroupsIcon from "@mui/icons-material/Groups";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import {
  styled,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  CardMedia,
  Box,
} from "@mui/material";

const drawerWidth = 240;
const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: "40px",
  fontWeight: "bold",
});

const StyledListItem = styled(ListItem)({
  marginLeft: "7%",
  marginRight: "7%",
  width: "auto",
  "&:hover": {
    backgroundColor: "#4a667e",
    borderRadius: "10px",
  },
});

const StyledAppSidebarLinkText = styled(ListItemText)({
  color: "white",
});

const AppSidebar = () => {
  const { adminLevel, setAdminLevel } = useContext(AdminContext);
  return (
    <>
      <Drawer
        sx={{
          position: "static",
          width: drawerWidth,
          flexShrink: 0,
          color: "success.main",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            bgcolor: "#213358",
          },
        }}
        variant="permanent"
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.900",
            color: "#FFFFFF",
            width: 280,
            zIndex: 1000,
          },
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "1.5rem",
            }}
          >
            <CardMedia
              component="img"
              image={CaselinkIcon}
              alt="icon"
              sx={{
                width: "50%",
              }}
            />
          </Box>
        </Toolbar>
        <Divider />
        <List>
          <Link to="roles">
            <StyledListItem>
              <StyledListItemIcon>
                <DoctorIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Roles" />
            </StyledListItem>
          </Link>
          <Link to="specialties">
            <StyledListItem>
              <StyledListItemIcon>
                <FindInPageIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Specialties" />
            </StyledListItem>
          </Link>
          <Link to="users-posts">
            <StyledListItem>
              <StyledListItemIcon>
                <ArticleIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Users Posts" />
            </StyledListItem>
          </Link>
          <Link to="admin-posts">
            <StyledListItem>
              <StyledListItemIcon>
                <AdUnitsIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Admin Posts" />
            </StyledListItem>
          </Link>
          <Link to="hashtags">
            <StyledListItem>
              <StyledListItemIcon>
                <TagIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Hashtags" />
            </StyledListItem>
          </Link>
          <Link to="communities">
            <StyledListItem>
              <StyledListItemIcon>
                <GroupsIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Communities" />
            </StyledListItem>
          </Link>
          <Link to="users">
            <StyledListItem>
              <StyledListItemIcon>
                <PeopleIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Users" />
            </StyledListItem>
          </Link>
          <Link to="Notifications">
            <StyledListItem>
              <StyledListItemIcon>
                <NotificationsIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Notifications" />
            </StyledListItem>
          </Link>
          <Link to="Metrics">
            <StyledListItem>
              <StyledListItemIcon>
                <QueryStatsIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Metrics" />
            </StyledListItem>
          </Link>
          <Link to="users-authorizations">
            <StyledListItem>
              <StyledListItemIcon>
                <HowToRegIcon
                  fontSize="small"
                  sx={{
                    color: "white",
                  }}
                />
              </StyledListItemIcon>
              <StyledAppSidebarLinkText primary="Authorizations" />
            </StyledListItem>
          </Link>
          {adminLevel.toString() === "1" && (
            <Link to="admins">
              <StyledListItem>
                <StyledListItemIcon>
                  <SensorOccupiedIcon
                    fontSize="small"
                    sx={{
                      color: "white",
                    }}
                  />
                </StyledListItemIcon>
                <StyledAppSidebarLinkText primary="Admins" />
              </StyledListItem>
            </Link>
          )}
        </List>
      </Drawer>
    </>
  );
};
export default AppSidebar;

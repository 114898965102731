"use strict";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import defaultStyle from "./mentions/defaultStyle";

// Components
import AppModal2 from "../components/AppModal2";
import AppBox from "../components/AppBox";
import AppSubmitButton from "../components/AppSubmitButton";
import AppCancelButton from "../components/AppCancelButton";

// Styles
import {
  Box,
  Grid,
  IconButton,
  styled,
  Typography,
  Input,
  Button,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Types
import {
  AddEditAdminPostModalFormProps,
  AddEditAdminPostModalProps,
  Hashtag,
} from "../types/adminPosts";

// Services
import {
  useAddAdminPostData,
  useUpdateAdminPostData,
} from "../services/adminPosts";
import { useCommunities } from "../services/communities";
import { useHashtags } from "../services/hashtags";
import { useUsers } from "../services/users";

import { MentionsInput, Mention } from "react-mentions";
import { User } from "../types/users";
import Loading from "./Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const AddEditAdminPostModal: FunctionComponent<AddEditAdminPostModalProps> = ({
  show,
  handleClose,
  isEditing,
  currentAdminPost,
}) => {
  const adminId = window.localStorage.getItem("currentAdminId");
  const [community, setCommunity] = useState<string>("");
  const { data: communities } = useCommunities();
  const { data: hashtags } = useHashtags({ limit: 1000 });
  const { data: users } = useUsers({ limit: 1000 });
  const { mutate: addAdminPost, isLoading: isAddingAdminPost } =
    useAddAdminPostData();
  const { mutate: editAdminPost, isLoading: isEditLoading } =
    useUpdateAdminPostData();
  let translatedContent: string | undefined = currentAdminPost?.content;

  const translateContent = () => {
    if (currentAdminPost?.hashtags) {
      currentAdminPost?.hashtags.forEach((postHashtag) => {
        translatedContent = translatedContent?.replace(
          postHashtag.id.toString(),
          postHashtag.value.toString()
        );
      });
    }
    if (currentAdminPost?.mentions) {
      currentAdminPost?.mentions.forEach((mention) => {
        translatedContent = translatedContent?.replace(
          mention.id.toString(),
          mention.value.toString()
        );
      });
    }
  };
  translateContent();
  const [userMention, setUserMention] = useState(translatedContent || "");
  const [pictureName, setPictureName] = useState(
    currentAdminPost?.postFiles.length > 0
      ? currentAdminPost?.postFiles[0].path.substring(54)
      : "Choose an image, video or pdf file"
  );

  const handleCommunityId = (name: any) => {
    const communityName = communities?.data.find((community) =>
      community.languajes.find((language) => language.name === name)
    );
    return communityName?.id;
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<AddEditAdminPostModalFormProps>({
    defaultValues: {
      title: "",
      content: "",
      community: handleCommunityId(currentAdminPost?.communityName) || "",
      photo: [],
      postId: "",
      userId: "",
      postHashtag: null,
      postUser: null,
    },
  });

  let postContentCopy2: any = getValues("content");
  const handleCloseModal = () => {
    handleClose();
    reset();
    setValue("community", "");
    setPictureName("Choose an image, video or pdf file");
  };

  useEffect(() => {
    const handleCommunityId = (name: any) => {
      const communityName = communities?.data.find((community) =>
        community.languajes.find((language) => language.name === name)
      );
      return communityName?.id;
    };
    if (show) {
      if (isEditing) {
        setValue("title", `${currentAdminPost?.title}`);
        setValue("content", `${translatedContent}`);
        setValue("postId", `${currentAdminPost?.id}`);
        setValue("photo", `${currentAdminPost?.postFiles[0]}`);
        setValue(
          "community",
          handleCommunityId(currentAdminPost?.communityName)
        );
      }
    }
  }, [
    show,
    currentAdminPost?.communityName,
    currentAdminPost?.content,
    currentAdminPost?.id,
    currentAdminPost?.postFiles,
    currentAdminPost?.title,
    isEditing,
    setValue,
    currentAdminPost,
    getValues,
    communities,
    translatedContent,
  ]);

  useEffect(() => {
    if (currentAdminPost?.postFiles.length > 0) {
      const initialName = currentAdminPost?.postFiles[0].path.substring(54);
      if (initialName.length > 50) {
        let name = initialName.substring(0, 40);
        name = name + ` (...)`;
        setPictureName(name);
      } else setPictureName(initialName);
    }
  }, [setPictureName, currentAdminPost?.postFiles]);

  const handleChange = (e: any) => {
    setValue("content", e.target.value);
    setUserMention(e.target.value);
  };

  const getUsersList: any =
    users &&
    users.data.map((user: User) => {
      return { display: user.fullName, id: user.id };
    });

  const getHashtagsList: any =
    hashtags &&
    hashtags.data.map((hashtag: Hashtag) => {
      return { display: hashtag.description, id: hashtag.id };
    });

  const fetchUsers = (query: string, callback: any) => {
    if (!query) return;

    setTimeout(() => {
      const filteredUsers = getUsersList.filter(
        (user: { display: string; id: number }) =>
          user.display.toLowerCase().includes(query)
      );
      callback(filteredUsers);
    }, 1000);
  };

  const fetchHashtags = (query: string, callback: any) => {
    if (!query) return;

    setTimeout(() => {
      const filteredHashtags = getHashtagsList.filter(
        (hashtag: { display: string; id: number }) =>
          hashtag.display.toLowerCase().includes(query)
      );
      callback(filteredHashtags);
    }, 1000);
  };

  const getUserNames = userMention.match(/@\[(.*?)\]/g);
  const getHashtagsNames = userMention.match(/#\[(.*?)\]/g);
  const getUserIdss = userMention.match(/@\((.*?)\)/g);
  const getHashtagsIds = userMention.match(/#\((.*?)\)/g);

  const updateContent = () => {
    getUserNames?.forEach((userName) => {
      postContentCopy2 = postContentCopy2.replace(userName.toString(), "");
    });
    getUserIdss?.forEach((userId) => {
      postContentCopy2 = postContentCopy2.replace(
        userId.toString(),
        `@${userId.slice(2, -1)}`
      );
    });
    getHashtagsNames?.forEach((hashtagName) => {
      postContentCopy2 = postContentCopy2.replace(hashtagName.toString(), "");
    });

    getHashtagsIds?.forEach((hashtagId) => {
      postContentCopy2 = postContentCopy2.replace(
        hashtagId.toString(),
        `#${hashtagId.slice(2, -1)}`
      );
    });
  };
  updateContent();

  if (isAddingAdminPost || isEditLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <>
      <AppModal2 open={show} onClose={() => handleCloseModal()}>
        <AppBox width="40%" padding="1.5rem">
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Typography variant="h6" gutterBottom component="div">
                {isEditing ? "Edit post" : "Add post"}
              </Typography>
              <IconButton
                sx={{ height: "30px" }}
                onClick={() => handleCloseModal()}
              >
                <CloseRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sx={{ width: "97%" }}>
              <Box sx={{ py: "1rem" }}>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Title</Box>
                <Input
                  placeholder="Title"
                  {...register("title", {
                    required: true,
                    maxLength: 100,
                  })}
                  sx={{ width: "100%" }}
                />
                {errors?.title?.type === "required" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> This field is required
                  </StlyedParagraph>
                )}
                {errors?.title?.type === "maxLength" && (
                  <StlyedParagraph>
                    <ErrorRoundedIcon fontSize="small" /> Max 100 characters
                    allowed.
                  </StlyedParagraph>
                )}
              </Box>
              <Box sx={{ color: "#8f8f8f", fontSize: ".9rem", pb: "5px" }}>
                Content
              </Box>
              {users && (
                <MentionsInput
                  name="content"
                  value={userMention}
                  onChange={handleChange}
                  style={defaultStyle}
                  allowSpaceInQuery
                >
                  <Mention
                    markup="@[__display__] @(__id__)"
                    trigger="@"
                    data={fetchUsers}
                    renderSuggestion={(
                      suggestion,
                      search,
                      highlightedDisplay
                    ) => <div className="user">{highlightedDisplay}</div>}
                    style={{
                      backgroundColor: "#c3b1c2",
                    }}
                  />
                  <Mention
                    markup="#[__display__] #(__id__)"
                    trigger="#"
                    data={fetchHashtags}
                    renderSuggestion={(
                      suggestion,
                      search,
                      highlightedDisplay
                    ) => <div className="user">{highlightedDisplay}</div>}
                    style={{
                      backgroundColor: "#cee4e5",
                    }}
                  />
                </MentionsInput>
              )}
              {errors?.content?.type === "required" && (
                <StlyedParagraph>
                  <ErrorRoundedIcon fontSize="small" /> This field is required
                </StlyedParagraph>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              textAlign="center"
              display="flex"
              sx={{ my: "1rem" }}
            >
              <Button
                component="span"
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  fontSize: ".9rem",
                  padding: ".2rem",
                  backgroundColor: "#e9ecef",
                  color: "#213358",
                  borderRadius: "10px",
                }}
              >
                <Typography sx={{ textTransform: "none" }}>
                  <label>
                    <Typography component={"span"}>{pictureName}</Typography>
                    <Input
                      style={{ display: "none" }}
                      inputProps={{ accept: "image/*,video/*,application/pdf" }}
                      type="file"
                      {...register("photo", {
                        onChange: (e) => {
                          if (e.target.files) {
                            if (e.target.files[0].name.length > 50) {
                              let name = e.target.files[0].name.substring(
                                0,
                                35
                              );
                              name = name + ` (...)`;
                              setPictureName(name);
                            } else setPictureName(e.target.files[0].name);
                          } else
                            setPictureName(
                              "Choose an image, video or pdf file"
                            );
                        },
                      })}
                    />
                  </label>
                </Typography>
              </Button>
              <Grid item xs={6}>
                <FormControl sx={{ m: 1, width: "97%" }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Community
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Optional Community"
                    defaultValue={
                      handleCommunityId(currentAdminPost?.communityName) || ""
                    }
                    {...register("community", {})}
                  >
                    {communities?.data.map((community) => {
                      const getEnCommunity = community?.languajes.find(
                        (language) => language.culture === "en"
                      );
                      return (
                        <MenuItem
                          key={getEnCommunity?.id}
                          value={handleCommunityId(getEnCommunity?.name) || ""}
                        >
                          {getEnCommunity?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Divider sx={{ width: "100%" }} />
            <Grid item xs={12} textAlign="end">
              <AppCancelButton onClick={() => handleCloseModal()}>
                Cancel
              </AppCancelButton>
              <AppSubmitButton
                submitbtnwidth="20%"
                onClick={handleSubmit((formValues) => {
                  if (isEditing && currentAdminPost) {
                    const editformData = new FormData();
                    editformData.append("title", formValues.title);
                    editformData.append("content", formValues.content);
                    editformData.append("files", formValues.photo[0]);
                    editformData.append("communities", community);
                    editformData.append(
                      "postId",
                      currentAdminPost.id.toString()
                    );
                    editformData.append(
                      "userId",
                      currentAdminPost.userId.toString()
                    );
                    editAdminPost(editformData);
                    reset();
                    handleCloseModal();
                  } else {
                    const formData = new FormData();
                    formData.append("title", formValues.title);
                    formData.append("content", postContentCopy2.toString());
                    formData.append("files", formValues.photo[0]);
                    formData.append("communities", formValues.community);
                    formData.append("userId", adminId as string);
                    addAdminPost(formData);
                    reset();
                    handleCloseModal();
                  }
                })}
              >
                Submit
              </AppSubmitButton>
            </Grid>
          </Grid>
        </AppBox>
      </AppModal2>
    </>
  );
};

export default AddEditAdminPostModal;

import React, { FunctionComponent } from "react";

// Styles
import {
  Modal,
  Typography,
  styled,
  Stack,
  Container,
  IconButton,
  FormGroup,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// Services
import {
  usePermissionsPerRole,
  useRolePermissions,
} from "../services/permissions";

// Components
import RolePermissionRow from "./RolePermissionRow";

// Types
import { PermissionModalProps } from "../types/permissions";

const StyledContainer = styled(Container)({
  width: 400,
  height: 240,
  backgroundColor: "#ffffff",
  borderRadius: 20,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
});

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const PermissionsModal: FunctionComponent<PermissionModalProps> = ({
  show,
  handleClose,
  roleInfo,
}) => {
  const { data: permissions } = useRolePermissions({ enabled: show });
  const { data: rolePermissions } = usePermissionsPerRole(roleInfo.id);
  const getHeader = () => {
    const header = roleInfo.languajes.find(
      (language) => language.culture === "en"
    );
    return header?.description;
  };

  return (
    <StyledModal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledContainer>
        <Stack direction={"row"} sx={{ width: "100%" }}>
          <Typography mb={3} flex={8} variant="h5" component="div">
            {getHeader()}
          </Typography>
          <IconButton sx={{ height: "30px" }} onClick={handleClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Stack>
          <FormGroup>
            {permissions?.map((permission) => (
              <RolePermissionRow
                key={permission.permissionId}
                role={roleInfo}
                permission={permission}
                isChecked={
                  rolePermissions?.data.some(
                    (rolePermission) =>
                      rolePermission.permissionId === permission.permissionId
                  ) || false
                }
              />
            ))}
          </FormGroup>
        </Stack>
      </StyledContainer>
    </StyledModal>
  );
};
export default PermissionsModal;

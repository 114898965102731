import React, { useState, FunctionComponent, useEffect } from "react";
import { useForm } from "react-hook-form";

// Styles
import { Input, styled } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Components
import AddEditModal from "../shared/AddEditModal";
import RoleRow from "../components/RoleRow";

// Services
import { useAddNewRoleData, useRoles } from "../services/roles";

// Components
import AppTableCard from "../shared/AppTableCard";
import AppSubmitButton from "../components/AppSubmitButton";
import AppContainer from "../components/AppContainer";
import Loading from "../shared/Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const RolesConfiguration: FunctionComponent = () => {
  const [openRolesModal, setOpenRolesModal] = useState(false);
  const handleOpenRolesModal = () => setOpenRolesModal(true);
  const handleCloseRolesModal = () => setOpenRolesModal(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { mutate: addRole, isLoading: isAddingRole } = useAddNewRoleData();
  const { data: roles, isLoading, isError } = useRoles(currentPage);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      key: "",
      descriptionEn: "",
      descriptionEs: "",
      descriptionPt: "",
    },
  });

  const handleCloseModal = () => {
    handleCloseRolesModal();
    reset();
  };
  useEffect(() => {
    if (roles?.data.length === 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, roles]);

  if (isLoading || isError || isAddingRole) {
    return <Loading showBack={isAddingRole ? true : false} />;
  }

  return (
    <>
      <AppContainer>
        <AppTableCard
          headerName="Roles configuration"
          headerEn="Es"
          headerEs="En"
          headerPt="Pt"
          itemsActions="Actions"
          handleOpenRolesModal={handleOpenRolesModal}
          data={roles}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          tooltipTitle="Add role"
        >
          {roles?.data.map((role) => (
            <RoleRow
              key={role.id}
              role={role}
              handleOpen={handleOpenRolesModal}
            />
          ))}
        </AppTableCard>
      </AppContainer>
      <AddEditModal
        handleClose={handleCloseModal}
        show={openRolesModal}
        modalHeader="Add new role"
        modalInputLabel="Name"
      >
        <>
          <Input
            placeholder="In Spanish"
            {...register("descriptionEs", {
              required: true,
            })}
          />
          {errors?.descriptionEs?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            placeholder="In English"
            {...register("descriptionEn", {
              required: true,
            })}
          />
          {errors?.descriptionEn?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            placeholder="In Portuguese"
            {...register("descriptionPt", {
              required: true,
            })}
          />
          {errors?.descriptionPt?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <AppSubmitButton
            onClick={handleSubmit((formValues) => {
              addRole([
                {
                  description: formValues.descriptionEs,
                  culture: "es",
                },
                {
                  description: formValues.descriptionEn,
                  culture: "en",
                },
                {
                  description: formValues.descriptionPt,
                  culture: "pt",
                },
              ]);
              reset();
              handleCloseModal();
            })}
          >
            Submit
          </AppSubmitButton>
        </>
      </AddEditModal>
    </>
  );
};
export default RolesConfiguration;

import { useMutation, useQuery, useQueryClient } from "react-query";
import http from "./axios";
import { GetCurrentAdminResponse } from "../types/admins";

export const useCurrentAdmin = (id: string | null) => {
  return useQuery<GetCurrentAdminResponse, Error>(
    ["requestCurrentAdmin"],
    async () => {
      const res = await http.get<
        "requestCurrentAdmin",
        { data: GetCurrentAdminResponse }
      >("admin-user/get-by-id", {
        params: { id: id },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
};

const addLogin = (token: { token: string | null }) => {
  return http.post(`authentication/login`, token);
};

export const useAddLogin = (callback: (user: any) => void) => {
  const queryClient = useQueryClient();
  return useMutation(addLogin, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(["requestCurrentAdmin"]);
      callback(response);
    },
    onError: (error) => {
      console.log("MY ERROR", error);
    },
  });
};

import React, { FunctionComponent, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Styles
import { Box, Button, CardMedia } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Types
import { AppCarouselProps } from "../types/customized";

const AppCarousel: FunctionComponent<AppCarouselProps> = ({ images }) => {
  const [currImage, setCurrImage] = useState(0);
  const [numPages, setNumPages] = useState<number>();
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const handleFiles = () => {
    if (images[currImage].fileType === 1 || images[currImage].fileType === 3) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ borderBottom: "1px solid #dee2e6", height: "100%" }}
        >
          {images.length > 1 && (
            <Button
              sx={{ minWidth: "5%" }}
              disabled={currImage === 0}
              onClick={() => setCurrImage(currImage - 1)}
            >
              <ArrowBackIosIcon fontSize="small" />
            </Button>
          )}
          <CardMedia
            component={images[currImage].fileType === 1 ? "img" : "video"}
            image={images[currImage].path}
            alt="post"
            autoPlay
            controls
            sx={{
              maxHeight: "60vh",
              minHeight: "50vh",
              objectFit: "contain",
              width: "90%",
            }}
          />
          {images.length > 1 && (
            <Button
              sx={{ minWidth: "5%" }}
              disabled={currImage === images.length - 1}
              onClick={() => setCurrImage(currImage + 1)}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </Button>
          )}
        </Box>
      );
    } else if (images[currImage].fileType === 2)
      return (
        <Box display="flex" sx={{ borderBottom: "1px solid #dee2e6" }}>
          <Button
            sx={{ minWidth: "5%" }}
            disabled={currImage === 0}
            onClick={() => setCurrImage(currImage - 1)}
          >
            <ArrowBackIosIcon fontSize="small" />
          </Button>
          <Box
            sx={{
              maxHeight: "50vh",
              overflow: "auto",
              overflowX: "hidden",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Document
              file={`${images[currImage].path}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </Box>
          <Button
            sx={{ minWidth: "5%" }}
            disabled={currImage === images.length - 1}
            onClick={() => setCurrImage(currImage + 1)}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </Button>
        </Box>
      );
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return <>{handleFiles()}</>;
};

export default AppCarousel;

import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from "react";

// types
import { AuthContextType, AuthProviderProps, User } from "../types/auth";

const AuthContext = createContext<AuthContextType>({
  user: {
    logged: false,
    fullName: "",
    id: null,
    email: "",
    levelAdmin: null,
  },
  setUser: (): void => {
    return undefined;
  },
});

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<User>({
    logged: false,
    fullName: "",
    id: null,
    email: "",
    levelAdmin: null,
  });

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

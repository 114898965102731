import React, { FunctionComponent } from "react";

// Styles
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AddIcon from "@mui/icons-material/Add";

// Components
import AppCard from "../components/AppCard";

// Types
import { AppTableCardProps } from "../types/customized";
import AppButton from "../components/AppButton";

const AppTableCard: FunctionComponent<AppTableCardProps> = ({
  handleOpenRolesModal,
  headerName,
  headerEs,
  headerEn,
  headerPt,
  itemsActions,
  data,
  currentPage,
  setCurrentPage,
  children,
  tooltipTitle,
}) => {
  return (
    <AppCard appcardwidth="90%">
      <TableContainer>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                colSpan={12}
                sx={{ color: "#213358", fontWeight: 600 }}
              >
                {headerName}
                <AppButton
                  tooltiptitle={tooltipTitle}
                  onClick={handleOpenRolesModal}
                >
                  <AddIcon sx={{ color: "#213358" }} />
                </AppButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left" sx={{ width: "25%" }}>
                {headerEn}
              </TableCell>
              <TableCell align="left" sx={{ width: "25%" }}>
                {headerEs}
              </TableCell>
              <TableCell align="left" sx={{ width: "25%" }}>
                {headerPt}
              </TableCell>
              <TableCell align="left" sx={{ width: "20%" }}>
                {itemsActions}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
          <TableFooter sx={{ borderTop: "1px solid rgb(224, 224, 224)" }}>
            <TableRow>
              <TableCell align="right" colSpan={4}>
                {`${currentPage} / ${Math.ceil((data?.count ?? 0) / 10)}`}
                <IconButton
                  disabled={data?.count === 0 || currentPage === 1}
                  onClick={() => setCurrentPage(currentPage - 1)}
                >
                  <ArrowBackIosRoundedIcon fontSize="small" />
                </IconButton>
                <IconButton
                  disabled={
                    data?.count === 0 ||
                    currentPage === Math.ceil((data?.count ?? 0) / 10)
                  }
                  onClick={() => setCurrentPage(currentPage + 1)}
                >
                  <ArrowForwardIosRoundedIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </AppCard>
  );
};

export default AppTableCard;

import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

export const msalConfig: any = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID ?? "missing-client-id",
    authority: process.env.REACT_APP_B2C_AUTHORITY,
    knownAuthorities: [
      process.env.REACT_APP_B2C_KNOWN_AUTHORITIES ??
        "missing-known-authorities",
    ],
    protocolMode: "OIDC",
    responseType: ["code"],
    redirectUri: process.env.REACT_APP_B2C_REDIRECT_URL,
    scopes: ["openid", "profile"],
  },

  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    navigateToLoginRequestUrl: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) {
          return;
        }
        // eslint-disable-next-line default-case
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["openid", "profile"],
};

import { useMutation, useQueryClient, useQuery } from "react-query";
import http from "./axios";

// Types
import {
  GetPostDetailResponse,
  GetPostsResponse,
  PostStatus,
} from "../types/posts";

// Get Requests
export const usePosts = (props?: {
  page?: number;
  postId?: number;
  userName?: string;
  datePublish?: number;
  OrderByReportDesc?: number;
  isBlocked?: boolean;
}) => {
  return useQuery<GetPostsResponse, Error>(
    [
      "requestsPosts",
      props?.page || 1,
      props?.postId,
      props?.userName,
      props?.datePublish,
      props?.OrderByReportDesc,
      props?.isBlocked,
    ],
    async () => {
      const res = await http.get<"requestsPosts", { data: GetPostsResponse }>(
        "publication/get-all-post",
        {
          params: {
            page: props?.page || 1,
            limit: 10,
            OrderByReportDesc: props?.OrderByReportDesc,
            postId: props?.postId,
            userName: props?.userName,
            datePublish: props?.datePublish,
            isBlocked: props?.isBlocked,
          },
        }
      );
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
    }
  );
};

export const usePostDetail = (postId?: string | number) => {
  return useQuery<GetPostDetailResponse, Error>(
    ["requestPostDetail", postId],
    async () => {
      const res = await http.get<
        "requestPostDetail",
        { data: GetPostDetailResponse }
      >("publication/post-detail-by-id", { params: { idPost: postId } });
      if (!res.data.isSuccess) {
        throw new Error(res.data.message);
      }
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 30000,
      retry: false,
    }
  );
};

const blockUnblockPost = (postStatus: PostStatus) => {
  return http.post(
    `publication/post-blocked?postId=${postStatus.postId}&blocked=${postStatus.blocked}`,
    postStatus
  );
};

export const useBlockUnblockData = (postId?: string | number) => {
  const queryClient = useQueryClient();
  return useMutation(blockUnblockPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestPostDetail", postId]);
    },
  });
};

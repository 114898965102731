import { useQuery } from "react-query";
import {
  GetProfilesResponse,
  ProfileToRoleResponse,
} from "../types/profilesType";
import http from "./axios";

export const useGetProfiles = (includeDeactivated?: boolean) => {
  return useQuery<GetProfilesResponse, Error>(
    ["requestProfiles", includeDeactivated],
    async () => {
      const res = await http.get<
        "requestRolesByCulture",
        { data: GetProfilesResponse }
      >(`profiles/get-all-profiles`, {
        params: { includeDeactivated: includeDeactivated },
      });
      return res.data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    }
  );
};

export const addProfileToUser = async (userId: number, profileId: number) => {
  return await http.post<"addProfileToUser", { data: ProfileToRoleResponse }>(
    `profiles/add-profile-to-user`,
    {
      profileId: profileId,
      userId: userId,
    }
  );
};

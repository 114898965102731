import * as React from "react";
import { useMsal } from "@azure/msal-react";
import { useAuth } from "../auth/auth";
import logo from "../assets/images/BlueLogo.png";

// Styles
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const AppNavbar = () => {
  const { instance } = useMsal();
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogOut = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      instance
        .logoutRedirect({
          idTokenHint: token,
          postLogoutRedirectUri:
            process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URL,
          onRedirectNavigate: () => {
            localStorage.removeItem("accessToken");
          },
        })
        .catch((e) => {
          console.error(e);
          setUser({
            logged: false,
            fullName: "",
            id: null,
            email: "",
            levelAdmin: null,
          });
        });
      window.localStorage.setItem("currentAdminId", JSON.stringify(""));
    }
  };

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "#ffffff",
        boxShadow: "none",
        flexGrow: 1,
        color: "#213358",
      }}
    >
      <Container
        maxWidth={false}
        sx={{
          boxShadow:
            "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.20),0px 1px 3px 0px rgba(0,0,0,0.12)",
          background: "#f1ff1",
          height: "65px",
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            ></Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}></Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1rem",
            }}
          >
            Admin
          </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{ border: "1px solid" }}
                  alt="Remy Sharp"
                  src={logo}
                />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="logout" onClick={handleCloseUserMenu}>
                <Typography onClick={() => handleLogOut()} textAlign="center">
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default AppNavbar;

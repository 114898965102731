import React, { useState } from "react";
import { Link } from "react-router-dom";

// Styles
import { Button, styled, TableCell, TableRow, Tooltip } from "@mui/material";
import EditSharpIcon from "@mui/icons-material/EditSharp";
// Types
import { AdminPostsRowProps } from "../types/adminPosts";

// Components
import AddEditAdminPostModal from "./AddEditAdminPostModal";

const StyledRow = styled(TableRow)({
  "&:hover": {
    backgroundColor: "#f1f1f1",
  },
});

const AdminPostsRow = ({ adminPost }: AdminPostsRowProps) => {
  const [openAdminPostEditing, setOpenAdminPostEditing] = useState(false);
  const handleCloseAdminPostEditing = () => setOpenAdminPostEditing(false);
  const handleAdminPostEdit = () => {
    setOpenAdminPostEditing(true);
  };

  return (
    <>
      <StyledRow>
        <TableCell align="center" colSpan={3}>
          <Link to={`/admin-post-detail/${adminPost.id}`}>
            <Button color="primary" variant="text">
              {adminPost.id}
            </Button>
          </Link>
        </TableCell>
        <TableCell align="left" colSpan={3}>
          {adminPost.title}
        </TableCell>
        <TableCell align="left" colSpan={3}>
          {adminPost.postDate.substring(0, 10)}
        </TableCell>
        <TableCell align="left" colSpan={3}>
          <Tooltip title="Edit" placement="top" arrow>
            <Button onClick={handleAdminPostEdit}>
              <EditSharpIcon sx={{ fontSize: 15, color: "#213358" }} />
            </Button>
          </Tooltip>
        </TableCell>
      </StyledRow>
      {openAdminPostEditing && (
        <AddEditAdminPostModal
          handleClose={handleCloseAdminPostEditing}
          show={openAdminPostEditing}
          isEditing={true}
          currentAdminPost={adminPost}
        />
      )}
    </>
  );
};

export default AdminPostsRow;

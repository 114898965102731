import React, { FunctionComponent } from "react";

// Styles
import { Card, styled } from "@mui/material";

// Types
import { AppCardProps } from "../types/customized";

const StyledAppCard = styled(Card)((props: AppCardProps) => ({
  borderRadius: props.appcardborderradius || "10px",
  height: props.appcardheight || "auto",
  width: props.appcardwidth || "auto",
}));

const AppCard: FunctionComponent<AppCardProps> = ({
  children,
  appcardborderradius,
  appcardwidth,
  appcardheight,
}) => {
  return (
    <StyledAppCard
      appcardborderradius={appcardborderradius}
      appcardwidth={appcardwidth}
      appcardheight={appcardheight}
    >
      {children}
    </StyledAppCard>
  );
};

export default AppCard;

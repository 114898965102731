import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { browserName } from "react-device-detect";

// Styles
import { Button, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Loading from "../shared/Loading";

const AppSignInButton = styled(Button)({
  background: "linear-gradient(to right, #213358, #3db1c8)",
  borderRadius: "20px",
  marginTop: "10%",
  width: "60%",
  color: "white",
});

export const SignInButton = () => {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();
  const handleLogin = async () => {
    navigate("/roles");

    await instance
      .loginRedirect(loginRequest)
      .then(() => {
        if (accounts) {
          if (browserName === "Firefox" || browserName === "Safari") {
            window.location.reload();
          }
        } else if (inProgress === "login") {
          console.log("In progress");
        }
      })
      .catch((e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e);
      });
  };
  return (
    <>
      <AppSignInButton onClick={handleLogin}>Sign in</AppSignInButton>
    </>
  );
};

import { useMutation, useQueryClient, useQuery } from "react-query";
import http from "./axios";

// Types
import { GetHashtagsResponse, HashtagStatus } from "../types/hashtags";

export const useHashtags = (props?: {
  page?: number;
  status?: number;
  description?: string;
  limit?: number;
}) => {
  return useQuery<GetHashtagsResponse, Error>(
    [
      "requestHashtags",
      props?.page,
      props?.status,
      props?.description,
      props?.limit,
    ],
    async () => {
      const res = await http.get<
        "requestHashtags",
        { data: GetHashtagsResponse }
      >("hashtag/get-all-hashtags", {
        params: {
          page: props?.page,
          limit: props?.limit || 10,
          status: props?.status,
          description: props?.description,
        },
      });
      return res.data;
    },
    { keepPreviousData: true }
  );
};

const addHashtag = (hashtag: { description: string }) => {
  return http.post("hashtag/add", hashtag);
};

export const useAddHashtagData = (page: number) => {
  const queryClient = useQueryClient();
  return useMutation(addHashtag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestHashtags", page]);
    },
  });
};

const blockUnblockHashtag = (hashtag: HashtagStatus) => {
  return http.post("hashtag/blocked-un-blocked", hashtag);
};

export const useBlockUnblockHashtagData = (page: number) => {
  const queryClient = useQueryClient();
  return useMutation(blockUnblockHashtag, {
    onSuccess: () => {
      queryClient.invalidateQueries(["requestHashtags", page]);
    },
  });
};

import React, { FunctionComponent } from "react";

// Styles
import { Box, styled } from "@mui/material";

// Types
import { AppContainerProps } from "../types/customized";

const StyledContainer = styled(Box)((props: AppContainerProps) => ({
  display: props.containerdisplay || "flex",
  justifyContent: props.containerjustifycontent || "center",
  width: props.containerwidth || "100%",
  marginRight: props.containermarginright || 0,
  backgroundColor: props.containerbackgroundcolor || "#f1f1f1",
  minHeight: props.containerheight || "calc(100vh - 65px)",
  alignItems: props.containeralignitems || "flex-start",
  paddingTop: props.containerPaddingTop || "5%",
  paddingBotton: props.containerPaddingBotton || "5%",
}));

const AppContainer: FunctionComponent<AppContainerProps> = ({
  children,
  containerdisplay,
  containerjustifycontent,
  containerwidth,
  containermarginright,
  containeralignitems,
  containerbackgroundcolor,
  containerheight,
  containerPaddingTop,
  containerPaddingBotton,
}) => {
  return (
    <StyledContainer
      containerdisplay={containerdisplay}
      containerjustifycontent={containerjustifycontent}
      containerwidth={containerwidth}
      containermarginright={containermarginright}
      containeralignitems={containeralignitems}
      containerbackgroundcolor={containerbackgroundcolor}
      containerheight={containerheight}
      containerPaddingTop={containerPaddingTop}
      sx={{ paddingBottom: containerPaddingBotton || "5%" }}
    >
      {children}
    </StyledContainer>
  );
};

export default AppContainer;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// Styles
import { Input, styled } from "@mui/material";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

// Services
import { useAddSpecialtyData, useSpecialties } from "../services/specialties";

// Components
import SpecialtyRow from "../components/SpecialtyRow";
import AppTableCard from "../shared/AppTableCard";
import AddEditModal from "../shared/AddEditModal";
import AppSubmitButton from "../components/AppSubmitButton";
import AppContainer from "../components/AppContainer";
import Loading from "../shared/Loading";

const StlyedParagraph = styled("p")({
  color: "#762d70",
});

const SpecialtiesConfiguration = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [openSpecialtyModal, setOpenSpecialtyModal] = useState(false);
  const handleOpenSpecialtyModal = () => setOpenSpecialtyModal(true);
  const handleCloseSpecialtyModal = () => setOpenSpecialtyModal(false);
  const { data: specialties, isLoading } = useSpecialties(currentPage);
  const { mutate: addSpecialty, isLoading: isAddingSpecialty } =
    useAddSpecialtyData();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      key: "",
      specialtyEn: "",
      specialtyEs: "",
      specialtyPt: "",
    },
  });

  const handleCloseModal = () => {
    handleCloseSpecialtyModal();
    reset();
  };

  useEffect(() => {
    if (specialties?.data.length === 0) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, specialties]);

  if (isLoading || isAddingSpecialty) {
    return <Loading showBack={true} />;
  }

  return (
    <>
      <AppContainer>
        <AppTableCard
          headerName="Specialties configuration"
          headerEn="Es"
          headerEs="En"
          headerPt="Pt"
          itemsActions="Actions"
          handleOpenRolesModal={handleOpenSpecialtyModal}
          data={specialties}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          tooltipTitle="Add specialty"
        >
          {specialties?.data.map((specialty) => (
            <SpecialtyRow
              key={specialty.id}
              specialty={specialty}
              currentPage={currentPage}
            />
          ))}
        </AppTableCard>
      </AppContainer>
      <AddEditModal
        handleClose={handleCloseModal}
        show={openSpecialtyModal}
        modalHeader="Add specialty"
        modalInputLabel="Name"
      >
        <>
          <Input
            placeholder="In Spanish"
            {...register("specialtyEs", {
              required: true,
            })}
          />
          {errors?.specialtyEs?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            placeholder="In English"
            {...register("specialtyEn", {
              required: true,
            })}
          />
          {errors?.specialtyEn?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <Input
            placeholder="In Portuguese"
            {...register("specialtyPt", {
              required: true,
            })}
          />
          {errors?.specialtyPt?.type === "required" && (
            <StlyedParagraph>
              <ErrorRoundedIcon fontSize="small" /> This field is required
            </StlyedParagraph>
          )}
          <AppSubmitButton
            onClick={handleSubmit((formValues) => {
              addSpecialty([
                {
                  description: formValues.specialtyEs,
                  culture: "es",
                },
                {
                  description: formValues.specialtyEn,
                  culture: "en",
                },
                {
                  description: formValues.specialtyPt,
                  culture: "pt",
                },
              ]);
              // addSpecialty({
              //   description: formValues.specialtyEn,
              //   culture: "en",
              // });
              // addSpecialty({
              //   description: formValues.specialtyPt,
              //   culture: "pt",
              // });
              reset();
              handleCloseModal();
            })}
          >
            Submit
          </AppSubmitButton>
        </>
      </AddEditModal>
    </>
  );
};

export default SpecialtiesConfiguration;

import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Styles
import {
  Box,
  CardMedia,
  Grid,
  styled,
  Typography,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";

// Services
import { useCommunities, useCommunityDetail } from "../services/communities";

// Components
import AppDetail from "../shared/AppDetail";
import AppContainer from "../components/AppContainer";

const StyledGrid = styled(Grid)({
  backgroundColor: "#ffffff",
  borderTopLeftRadius: "50px",
  borderTopRightRadius: "15px",
  borderBottomLeftRadius: "15px",
  borderBottomRightRadius: "30px",
  width: "65%",
  height: "auto",
  flexDirection: "column",
});

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const StyledTabPanel = styled(TabPanel)({
  overflow: "auto",
  height: "45vh",
  marginRight: ".5rem",
  marginBottom: "1rem",
});

const NotificationDetail = () => {
  const [value, setValue] = useState(6);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === value) {
      setValue(6);
    } else {
      setValue(newValue);
    }
  };
  const params = useParams<{ communityId: string }>();
  const { data: communities } = useCommunities({ limit: 100000 });
  const { data: communityDetail } = useCommunityDetail(params.communityId);
  const getCommunity = communities?.data.find(
    (community) => community.id.toString() === params.communityId
  );

  const getEsCommunity = getCommunity?.languajes.find(
    (language) => language.culture === "es"
  );

  const getEnCommunity = getCommunity?.languajes.find(
    (language) => language.culture === "en"
  );

  const getPtCommunity = getCommunity?.languajes.find(
    (language) => language.culture === "pt"
  );

  return (
    <AppContainer>
      <StyledGrid
        container
        spacing={0}
        columnSpacing={4}
        alignSelf="flex-start"
        sx={{ width: "90%" }}
      >
        <Grid display="flex" sx={{ paddingLeft: 0, minHeight: "30vh" }}>
          {getCommunity?.photo && (
            <Grid item xs={3}>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                sx={{ height: "100%" }}
              >
                <CardMedia
                  component="img"
                  image={getCommunity?.photo}
                  alt="Community"
                  sx={{
                    width: "100%",
                    borderTopLeftRadius: "50px",
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={getCommunity?.photo ? 9 : 12}
            display="flex"
            flexDirection="row"
            alignItems="flex-start"
            padding="1rem"
          >
            <Grid item xs={4} textAlign="left">
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  EN
                </Typography>
                <Box sx={{ pb: "1rem" }}>
                  <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                  <Box
                    sx={{
                      fontSize: ".8rem",
                      wordWrap: "anywhere",
                      minHeight: "3vh",
                      pt: "3%",
                      pr: "5%",
                      textAlign: "justify",
                    }}
                  >
                    {getEnCommunity?.name}
                  </Box>
                </Box>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                  Description
                </Box>
                <Box
                  sx={{
                    fontSize: ".8rem",
                    wordWrap: "anywhere",
                    minHeight: "10vh",
                    pt: "3%",
                    pr: "5%",
                    textAlign: "justify",
                  }}
                >
                  {getEnCommunity?.description}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={4} textAlign="left">
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  ES
                </Typography>
                <Box sx={{ pb: "1rem" }}>
                  <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                  <Box
                    sx={{
                      fontSize: ".8rem",
                      wordWrap: "anywhere",
                      minHeight: "3vh",
                      pt: "3%",
                      pr: "5%",
                      textAlign: "justify",
                    }}
                  >
                    {getEsCommunity?.name}
                  </Box>
                </Box>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                  Description
                </Box>
                <Box
                  sx={{
                    fontSize: ".8rem",
                    wordWrap: "anywhere",
                    minHeight: "10vh",
                    pt: "3%",
                    pr: "5%",
                    textAlign: "justify",
                  }}
                >
                  {getEsCommunity?.description}
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={4} textAlign="left">
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom component="div">
                  PT
                </Typography>
                <Box sx={{ pb: "1rem" }}>
                  <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>Name</Box>
                  <Box
                    sx={{
                      fontSize: ".8rem",
                      wordWrap: "anywhere",
                      minHeight: "3vh",
                      pt: "3%",
                      textAlign: "justify",
                    }}
                  >
                    {getPtCommunity?.name}
                  </Box>
                </Box>
                <Box sx={{ color: "#8f8f8f", fontSize: ".9rem" }}>
                  Description
                </Box>
                <Box
                  sx={{
                    fontSize: ".8rem",
                    wordWrap: "anywhere",
                    minHeight: "10vh",
                    pt: "3%",
                    textAlign: "justify",
                  }}
                >
                  {getPtCommunity?.description}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid item xs={12} sx={{ paddingLeft: 0 }}>
          <Tabs
            value={false}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="View posts" {...a11yProps(0)} />
          </Tabs>
        </Grid>
        <StyledTabPanel value={value} index={0}>
          {communityDetail?.data.map((posts) => (
            <AppDetail
              key={posts.id}
              posts={posts}
              communityId={params.communityId}
            />
          ))}
        </StyledTabPanel>
      </StyledGrid>
    </AppContainer>
  );
};

export default NotificationDetail;

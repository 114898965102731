import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";

// Styles
import {
  Box,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// Types
import { UsersFilterModalProps } from "../types/users";

// Components
import AppCancelButton from "../components/AppCancelButton";
import AppSubmitButton from "../components/AppSubmitButton";

const StyledModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const UsersFilterModal: FunctionComponent<UsersFilterModalProps> = ({
  show,
  handleClose,
  setUserName,
  setEmail,
}) => {
  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const handleCloseModal = () => {
    reset();
    handleClose();
  };
  return (
    <StyledModal
      open={show}
      onClose={() => handleCloseModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        width={400}
        height="auto"
        bgcolor="white"
        p={3}
        borderRadius={5}
        padding={3}
      >
        <FormGroup
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px",
          }}
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography
                flex={8}
                variant="h5"
                component="div"
                sx={{ mb: "6%" }}
              >
                Filters
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={() => handleCloseModal()}>
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <FormLabel>User name</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Input {...register("name")} />
            </Grid>
            <Grid item xs={4} alignSelf="center">
              <FormLabel>Email</FormLabel>
            </Grid>
            <Grid item xs={8}>
              <Input {...register("email")} />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent={"flex-end"}
              sx={{ mt: "7%" }}
            >
              <AppCancelButton onClick={() => handleCloseModal()}>
                Cancel
              </AppCancelButton>
              <AppSubmitButton
                submitbtnwidth="20%"
                onClick={handleSubmit((data) => {
                  setUserName(data.name);
                  setEmail(data.email);
                  reset();
                  handleClose();
                })}
              >
                Apply
              </AppSubmitButton>
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
    </StyledModal>
  );
};

export default UsersFilterModal;

import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Styles
import {
  styled,
  Box,
  Grid,
  Button,
  Typography,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  Switch,
  Divider,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Swal from "sweetalert2";

// Services
import { useBlockUnblockData, usePostDetail } from "../services/posts";

// Components
import PostComment from "../components/PostComment";
import PostReport from "../components/PostReport";
import AppCarousel from "../shared/AppCarousel";
import NotFound from "./NotFound";
import AppContainer from "../components/AppContainer";

// Types
import { Post } from "../types/posts";
import Loading from "../shared/Loading";
import AppSubmitButton from "../components/AppSubmitButton";

const StyledGrid = styled(Grid)({
  backgroundColor: "white",
  borderRadius: "10px",
  padding: "0",
  marginLeft: "10%",
  marginRight: "10%",
  minHeight: "30vh",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.20),0px 1px 3px 0px rgba(0,0,0,0.12);",
});

const StyledPostDetailUserName = styled(Box)({
  textAlign: "left",
  fontSize: "1.3rem",
  color: "#3db1c8",
  fontWeight: "500",
  marginBottom: "3%",
});

const StyledPostDetailIdBox = styled(Box)({
  fontSize: "1rem",
  textAlign: "left",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PostDetail = () => {
  const params = useParams<{ postId: string }>();
  const [value, setValue] = useState(6);
  const { mutate: handleBlockStatus } = useBlockUnblockData(params.postId);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === value) {
      setValue(6);
    } else {
      setValue(newValue);
    }
  };

  const { data: postDetail, isError, isLoading } = usePostDetail(params.postId);
  const post: Post | undefined = postDetail?.data;
  if (isError) {
    return <NotFound />;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            display="flex"
            alignItems="center"
            sx={{
              maxHeight: `${
                postDetail?.data.postFiles.length === 0 ? "44vh" : "auto"
              }`,
              overflow: "auto",
            }}
          >
            <Typography
              sx={{
                width: "100%",
                px: "2rem",
                maxHeight: "44vh",
                overflow: "auto",
              }}
              component={"span"}
            >
              {children}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }

  const StyledPostCommentTabPanel = styled(TabPanel)({
    overflowY: "hidden",
    maxHeight: "45vh",
    padding: 0,
  });

  const handleStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    Swal.fire({
      title: `Do you want to ${
        e.target.checked ? "block" : "activate"
      } this post?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3db1c8",
      cancelButtonColor: "#cacaca",
      confirmButtonText: "Yes!",
      iconColor: "#762d70",
    }).then((result) => {
      if (result.isConfirmed) {
        handleBlockStatus({
          postId: post?.id,
          blocked: !e.target.checked,
        });
      }
    });
  };

  let translatedContent: string | undefined = post?.content;

  const translateHashtags = () => {
    if (post?.hashtags) {
      post?.hashtags.forEach((postComment) => {
        translatedContent = translatedContent?.replace(
          postComment.id.toString(),
          postComment.value.toString()
        );
      });
    }
    if (post?.mentions) {
      post?.mentions.forEach((postMention) => {
        translatedContent = translatedContent?.replace(
          postMention.id.toString(),
          postMention.value.toString()
        );
      });
    }
  };

  const handleCopyLink = () => {
    const idpost = window.location.pathname.split("/").pop();
    const linkToPost = `https://app.caselink.network/post/${idpost}`;

    navigator.clipboard
      .writeText(linkToPost)
      .catch(() => console.error("Error al copiar el enlace al portapapeles"));
  };

  translateHashtags();

  if (isLoading) {
    return <Loading showBack={true} />;
  }

  return (
    <AppContainer containerPaddingTop="2%">
      <StyledGrid container spacing={0} alignSelf="flex-start">
        <Grid item xs={12} display="flex" sx={{ paddingLeft: 0 }}>
          {post && post.postFiles.length > 0 && (
            <Grid item xs={7} display="flex" flexDirection="column">
              <AppCarousel images={post.postFiles} />
            </Grid>
          )}
          <Divider orientation="vertical" />
          <Grid
            container
            xs={post && post.postFiles.length > 0 ? 5 : 12}
            display="flex"
            flexDirection="row"
            padding="1.3rem"
            alignContent="flex-start"
          >
            {post?.communityName && (
              <Grid
                item
                xs={12}
                sx={{
                  justifyContent: "flex-start",
                  display: "flex",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#8c8c8c",
                    paddingRight: "1%",
                    fontSize: ".8rem",
                  }}
                >
                  {"Publicated in "}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#3db1c8", fontWeight: 500, fontSize: ".8rem" }}
                >
                  {post && post.communityName}
                </Typography>
              </Grid>
            )}
            <Grid item xs={8} alignItems="left">
              <StyledPostDetailUserName>
                {post && post.userName}
              </StyledPostDetailUserName>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="flex-start"
              justifyContent={
                post && post.postFiles.length > 0 ? "center" : "flex-end"
              }
              paddingRight={post && post.postFiles.length > 0 ? 0 : "2%"}
            >
              <StyledPostDetailIdBox>{post && post.id}</StyledPostDetailIdBox>
            </Grid>
            <Divider style={{ width: "100%" }} />
            <Grid item xs={8} display="flex" alignItems="center">
              <Box sx={{ fontSize: ".8rem", color: "#8f8f8f" }}>
                {post && post.postDate.substring(0, 10)}
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              alignItems="center"
              justifyContent={
                post && post.postFiles.length > 0 ? "center" : "flex-end"
              }
            >
              <Box>
                <FormGroup>
                  <FormControlLabel
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    control={
                      <Switch
                        size={"small"}
                        checked={post?.isBlocked === true}
                        onChange={(e) => handleStatus(e)}
                        color="primary"
                      />
                    }
                    label={`${post?.isBlocked === true ? "Blocked" : "Active"}`}
                  />
                </FormGroup>
              </Box>
            </Grid>
            <Grid item xs={12} display="flex flex-col">
              <Box
                sx={{
                  maxHeight:
                    post && post.postFiles.length > 0 ? "43vh" : "25vh",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  paddingRight: "5px",
                  whiteSpace: "initial",
                  wordBreak: "break-word",
                  textOverflow: "clip",
                  textAlign: "initial",
                  marginTop: "5%",
                  fontSize: ".9rem",
                  maxWidth: "400px",
                  width: "400px",
                }}
              >
                {translatedContent}
              </Box>
              <AppSubmitButton
                submitbtnmargintop="30px"
                submitbtnwidth="70%"
                onClick={handleCopyLink}
                id="buttonPreAuthorized"
              >
                Copy Link
              </AppSubmitButton>
            </Grid>
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
        <Grid item xs={12} display="flex" alignItems="center">
          <Box
            sx={{
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button disabled startIcon={<ThumbUpIcon fontSize="small" />}>
              {postDetail?.data.reacctions}
            </Button>
            <Tabs
              value={false}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                disabled={post && post.commentsCount === 0}
                label={
                  post && post.commentsCount === 1
                    ? `${post.commentsCount} comment`
                    : `${post?.commentsCount} comments`
                }
                {...a11yProps(0)}
              />
              <Tab
                disabled={post && post.reportsCount === 0}
                label={
                  post && post.reportsCount === 1
                    ? `${post.reportsCount} report`
                    : `${post?.reportsCount} reports`
                }
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ paddingLeft: 0 }}>
            <StyledPostCommentTabPanel value={value} index={0}>
              {post &&
                post.comments.map((comment) => (
                  <PostComment key={comment.id} postComment={comment} />
                ))}
            </StyledPostCommentTabPanel>
            <StyledPostCommentTabPanel value={value} index={1}>
              {post &&
                post.reports.map((report) => (
                  <PostReport key={report.usuario} postReport={report} />
                ))}
            </StyledPostCommentTabPanel>
          </Grid>
        </Grid>
      </StyledGrid>
    </AppContainer>
  );
};

export default PostDetail;
